import React from "react";
import "./Completa.css";
import { Row, Col, Container, Form } from "react-bootstrap";
import axios from 'axios';
import { Formik } from 'formik';
import Hitos from '../hitos/hitos';
import global from '../../../../config';
import {toast, ToastContainer} from "react-toastify";
import {connect, useSelector, useDispatch} from "react-redux";
import store from '../../../../redux/store';
 function Completa(props) {

  
  
  const {setActiveKey, pasos} = props; 
  //console.log(props);
  console.log(pasos)
  //const {titulo} = useSelector(state => state.pasos);
  const [hitos, setItos] = React.useState(false);
  const [historiaVital, setHistoriaVital] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [dataHistoria,setDataHistoria]=React.useState({titulo: ''});  

  React.useEffect(() => {
    //setDataHistoria({titulo: 'daniel'})

    //console.log(titulo, 'mostrando el título');
    //const {pasos} = useSelector( state => state );
    getHistoriaVital();
  }, []);

  const guardar = () => {
    props.history.push("hitos");
  }

  async function nuevaHistoriaVital(datos) {
    setIsSubmitting(true);
    await axios.post(global.url+'entrevistas/addHistoriaVital', datos, { headers: { 'token': localStorage.getItem('token') } })
      .then(response => {
        setIsSubmitting(false);
        //console.log(response);
        if(response.data.message  == 'success'){
          getHistoriaVital();
            toast.success("Guardado exitosamente");
               
        }
      })
      .catch(error => {
        setIsSubmitting(false);
        console.log(error);
      });
  }

  async function getHistoriaVital() {

    await axios.get(global.url+'entrevistas/listHistoriaVital', { headers: { 'token': localStorage.getItem('token') } })
      .then(response => {
        //console.log(response.data, 'historia vital');
        let data = response.data.data;
        //console.log(data, 'mostrando data')
        
        if (Object.entries(response.data.data).length > 0) {
          setItos(true);
          setHistoriaVital(response.data.data)
        } else {
          setItos(false);
        }
      })
      .catch(error => {
        console.log(error);
      });

  }
  return (
    <div>
        <ToastContainer />
      {!hitos ?

        <Container>
          <Row>
            <Col>
              <h4>
                <strong>Historia vital</strong></h4>
            </Col>
          </Row>
          <Row>
            <Col>
                <Formik
                    initialValues={{
                      titulo: props.pasos.titulo
                    }}
                    validate={values => {
                        const errors = {};
                        if (!values.titulo) {
                            errors.titulo = 'Requerido';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setIsSubmitting }) => {
                        console.log(values);
                        nuevaHistoriaVital(values);
                        //setIsSubmitting(false);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <Col className="d-flex justify-content-center">
                            <Form onSubmit={handleSubmit}>
                                <div className="completa">

                                    <Row className="fila">
                                        <Col xs={12} md={3}>
                                            Título
                                        </Col>
                                        <Col xs={12} md={9}>
                            <textarea name="titulo" onChange={handleChange} onBlur={handleBlur} defaultValue={values.titulo} placeholder="P.ej: tu nombre, o el nombre de quien/es está dedicado tu Almadentro" className="form-control">
                              
                            </textarea>
                                            {errors.titulo && touched.titulo && errors.titulo}
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col xs={12} md={3}>
                                            Temas a tratar
                                        </Col>
                                        <Col xs={12} md={9}>
                            <textarea name="temas_a_tratar" onChange={handleChange} onBlur={handleBlur} placeholder="P.ej: la infancia, la carrera, la familia" className="form-control">
                              {values.temas_a_tratar}
                            </textarea>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col xs={12} md={3}>
                                            Temas a evitar
                                        </Col>
                                        <Col xs={12} md={9}>
                            <textarea name="temas_a_evitar" onChange={handleChange} onBlur={handleBlur} placeholder="P.ej: la guerra, los que ya no están..." className="form-control">
                              {values.temas_a_evitar}
                            </textarea>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col xs={12} md={3}>
                                            Otros comentarios
                                        </Col>
                                        <Col xs={12} md={9}>
                            <textarea name="otros_comentarios" onChange={handleChange} onBlur={handleBlur} className="form-control">
                              {values.otros_comentarios}
                            </textarea>
                                        </Col>
                                    </Row>
                                    <Row className="fila">
                                        <Col xs={12} md={3}></Col>
                                        <Col xs={12} md={9}>
                                            <button type="submit" disabled={isSubmitting} className="btn md-button-solid">
                                                Guardar
                                            </button>
                                        </Col>
                                    </Row>

                                </div>
                            </Form>
                        </Col>

                    )}
                </Formik>
            </Col>
        </Row>
        </Container>

        :

        <Hitos setItos={setItos} setDataHistoria={setDataHistoria} historiaVital={historiaVital}/> 

      }
    </div>

  );
}

const mapStateToProps = (state)=> {
  return {
    pasos : state.pasos
  }
}

const wrapper = connect(mapStateToProps);

const component = wrapper(Completa);

export default component;