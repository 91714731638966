import React,{useState} from "react";
import "./misdatos.css";
import { Row, Col, Container, Card, Form } from "react-bootstrap";
import Checkbox from '@material-ui/core/Checkbox';
import { Formik } from 'formik';
import { Auth } from "aws-amplify";
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function MisDatos(props) {


  
const [checked, setChecked] = useState(true);
const [pago, setPago]=useState(false);


  
  
  
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const changePassword=async(datos)=>{
    console.log(datos.old_password+" y "+datos.new_password);
    try{
    let user = await Auth.currentAuthenticatedUser();
    const change=await Auth.changePassword(user,datos.old_password,datos.new_password);
    Swal.fire({
        title: 'Proceso realizado',
        text: 'La contraseña ha sido cambiada',
        icon: 'success',
        confirmButtonText: 'Sí'
      })
    }catch(e){
        Swal.fire({
            title: 'Error',
            text: 'La contraseña no pudo ser cambiada',
            icon: 'error',
            confirmButtonText: 'Sí'
          })
    }
    //console.log(change);

  }
  
  const register=async (datos)=>{
     try {      
       datos.username=datos.email;
       let user = await Auth.currentAuthenticatedUser();
       const attributes={                                           
        "address":datos.address,
        "name":datos.name,
        "family_name":datos.family_name,
        "phone_number":datos.phone_number,
        "custom:ciudad":datos.ciudad,
        "custom:provincia":datos.provincia,
        "custom:codigo_postal":datos.codigo_postal,
        "custom:dni":datos.dni
      };
       const newUser=await Auth.updateUserAttributes(user,attributes);       
       props.setAttributes(attributes);
       toast.success('Los cambios han sido guardados');
       }catch(err){
       console.log(err.message);
       //throw new Error(err.message);
         toast.error('Los datos no pudieron ser actualizados');
        }
  
    
  }
  
  const ViewPedido = () => {


    let atributos=JSON.stringify(props.attributes);
    let salida=JSON.parse(atributos.split("custom:").join(""));
    return (
      <Container className="p-0">    

   
    <Row className="mt-0">
      <Col lg={7}>
        <Card>
          <Card.Body>
          <Formik
      initialValues={salida}
    //   validate={values => {
    //     const errors = {};
    //     if (!values.email) {
    //       errors.email = 'Requerido';
    //     } else if (
    //       !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    //     ) {
    //       errors.email = 'Email invalido';
    //     }

    //     if(!values.name){
    //       errors.name='Requerido';
    //     }

    //     if(!values.family_name){
    //       errors.family_name='Requerido';
    //     }

    //     if(!values.address){
    //       errors.address='Requerido';
    //     }

    //     if(!values.provincia)
    //     {
    //        errors.provincia='Requerido';
    //     }

    //     if(!values.ciudad)
    //     {
    //        errors.ciudad='Requerido';
    //     }
        

    //     if(!values.codigo_postal){
    //       errors.codigo_postal='Requerido';
    //     }

    //     if(isNaN(values.codigo_postal)){
    //       errors.codigo_postal='No es código valido';
    //     }

    //     if(!values.dni){
    //       errors.dni='Requerido';
    //     }

    //     if(isNaN(values.dni)){
    //       errors.dni='No es un DNI valido';
    //     }

    //     if(!values.phone_number){
    //       errors.phone_number='Requerido';
    //     }

    //     if(isNaN(values.phone_number)){
    //       errors.phone_number='No es un número valido';
    //     }

    //     if(!values.password){
    //       errors.password='Requerido';
    //     }

    //     return errors;
    //   }
    // }
      onSubmit={(values, { setSubmitting }) => {
        const datos=JSON.stringify(values, null, 2);
        console.log(datos);
        register(values);
        //handlePago();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
    <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group>
                  <Col>
                    <h5>Datos de facturación</h5>
                  </Col>
                </Form.Group>
              </Form.Row>
              <Form.Row>              
                <Col lg={6}>
                  <Form.Group>                    
                    <input type="text" name="name"  onChange={handleChange}
                     onBlur={handleBlur} value={values.name} className="form-control customInput" placeholder="Nombre"></input>
                     {errors.name && touched.name && errors.name}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <input type="text" name="family_name" onChange={handleChange}
                     onBlur={handleBlur} value={values.family_name} className="form-control customInput" placeholder="Apellido"></input>
                     {errors.family_name && touched.family_name && errors.family_name}
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <input type="text" name="address" onChange={handleChange}
                     onBlur={handleBlur} value={values.address} className="form-control customInput" placeholder="Dirección"></input>
                     {errors.address && touched.address && errors.address}
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col lg={6}>
                  <Form.Group>
                    <input type="text" name="provincia" onChange={handleChange}
                     onBlur={handleBlur} value={values.provincia} className="form-control customInput" placeholder="Provincia"></input>
                     {errors.provincia && touched.provincia && errors.provincia}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <input type="text" name="ciudad" onChange={handleChange}
                     onBlur={handleBlur} value={values.ciudad} className="form-control customInput" placeholder="Ciudad"></input>
                     {errors.ciudad && touched.ciudad && errors.ciudad}
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col lg={6}>
                  <Form.Group>
                    <input type="text" name="codigo_postal" onChange={handleChange}
                     onBlur={handleBlur} value={values.codigo_postal}  className="form-control customInput" placeholder="Código Postal"></input>
                     {errors.codigo_postal && touched.codigo_postal && errors.codigo_postal}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <input type="text" name="dni"  onChange={handleChange}
                     onBlur={handleBlur} value={values.dni} className="form-control customInput" placeholder="DNI"></input>
                     {errors.dni && touched.dni && errors.dni}
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col lg={6}>
                  <Form.Group>
                    <input type="text" name="phone_number"  onChange={handleChange}
                     onBlur={handleBlur} value={values.phone_number} className="form-control customInput" placeholder="Teléfono"></input>
                     {errors.phone_number && touched.phone_number && errors.phone_number}
                  </Form.Group>
                </Col>
              </Form.Row>
              <Row>
                <Col>
                  <button  type="submit" disabled={isSubmitting} className="btn md-button-solid mt-3">
                    Guardar
                  </button>
                </Col>
              </Row>       
              <hr />
              {/* SEPARADOR */}   
              </Form>
            )}
            </Formik>       

            <Formik
                initialValues={salida}
                  validate={values => {
                    const errors = {};
                    if (!values.old_password) {
                      errors.old_password = 'Requerido';
                    }

                    if (!values.new_password) {
                        errors.new_password = 'Requerido';
                    }
                    

                    return errors;
                  }
                }
                onSubmit={(values, { setSubmitting }) => {                    
                    changePassword(values);        
                    setSubmitting(false);            
                }}
                >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group>
                            <Col>
                                <h5>Datos de Acceso</h5>
                            </Col>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>                
                            <Col lg={6}>
                            <Form.Group>
                                <input type="password" name="old_password" onChange={handleChange}
                                onBlur={handleBlur} value={values.old_password} className="form-control customInput" placeholder="Contraseña antigua"></input>
                                {errors.old_password && touched.old_password && errors.old_password}
                            </Form.Group>
                            </Col>
                            <Col lg={6}>
                            <Form.Group>
                                <input type="password" name="new_password" onChange={handleChange}
                                onBlur={handleBlur} value={values.new_password} className="form-control customInput" placeholder="Contraseña nueva"></input>
                                {errors.new_password && touched.new_password && errors.new_password}
                            </Form.Group>
                            </Col>
                        </Form.Row>                    
                        <Row>
                            <Col>
                            <button  type="submit"  disabled={isSubmitting} className="btn md-button-solid mt-3">
                                Guardar
                            </button>
                            </Col>
                        </Row>       
                    </Form>
                    )}
                    </Formik>     

                    <Row>
                        <Col>
                        <button  type="button" onClick={()=>props.setModificar(false)}  className="btn md-button-solid mt-3">
                            Cancelar
                        </button>
                        </Col>
                    </Row>       
          </Card.Body>
        </Card>
      </Col>
      <Col lg={5}>
       
      </Col>
    </Row>

    
  </Container>
    );
 
  }

  return (
    <div>
        <ToastContainer />
        <ViewPedido />
    </div>    
  );
}