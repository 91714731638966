import types from '../types/types';
const loginAction = (token) => {
    return{
        type: types.login,
        payload: {
            token
        }
    }

}
export default loginAction;

