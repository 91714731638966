import React from "react";
import { Route, Redirect } from "react-router-dom";
import axios from 'axios';
import config from '../../../config';
export default function AuthenticatedRoute({ rol,component: C, appProps, ...rest }) {  

  const [pago, setPago] = React.useState(false);
  const token=localStorage.getItem("token");
  let redirect=null;

  if(token){ 
  
  const resolve=JSON.parse(atob(token.split('.')[1]));
  const roleToken=resolve["custom:rol"];  


    if(roleToken==="client" && rol==="admin"){
        redirect="/tualmadentro"; 
    }else if(roleToken==="admin" && rol==="client"){
      redirect="/admin/dashboard";
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        appProps.isAuthenticated
          ? redirect===null?<C {...props} {...appProps} />: <Redirect to={redirect}/>          
          : <Redirect
              to={`/?redirect=${props.location.pathname}${props.location
                .search}`}
            />}
    />
  );

           
  
  
}