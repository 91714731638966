import React, { useImperativeHandle, useRef } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import global from '../../../../config';
import Swal from 'sweetalert2';

const NewItem = (props) => {


    console.log(props);

    const [archivos,setArchivos]=React.useState([]);
    const [historia,setHistoria]=React.useState("");
    const [epoca,setEpoca]=React.useState(""); 
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [remover,setRemover]=React.useState([]);
    const [multimedia,setMultimedia]=React.useState([]);   
    const [imagenes,setImagenes]=React.useState([]);    
    

    React.useEffect(()=>{
      if(props.item!=undefined){
          setEpoca(props.item.fecha);
          setHistoria(props.item.descripcion);
          setMultimedia(props.item.multimedia);                  
      }
    },[])


    // const getImagen=async(key)=>{        
    //     const cache = await caches.open('files');
    //     const data=await cache.match(key);                
    //     return data.body;           
    // }


    const  guardar=async()=>{
        setIsSubmitting(true);
        console.log("HA GUARDAR");
        const formData = new FormData();
        archivos.forEach((value,index)=>{
            formData.append("archivos",value); 
        });        

      
        formData.append("descripcion",historia);
        formData.append("fecha",epoca);
        formData.append("posicion",1);
        formData.append("entrevistaID",1234);

        let url=global.url+'historiavital/addHistoriaVital';
        let method="post";
        if(props.item!==undefined){
            url=global.url+'historiavital/updateHistoriaVital';            
            formData.append("remover",JSON.stringify(remover));
            formData.append("id",props.item.id);
            method="put";
        }
        
        const salida=await axios({
            method: method,
            headers: {                
                'token': localStorage.getItem('token')
            },
            data: formData,
            url: url,
            onUploadProgress: (ev) => {
                const progress = ev.loaded / ev.total * 100;
                //updateUploadProgress(Math.round(progress));
                console.log(progress);
            },
        });
     
        if(salida.status===200){
            setIsSubmitting(false);
            setHistoria(false);
            if(props.item){                
                //props.onClick();                
            }
            //console.log("SI FUNCIONA");
            props.onClick();                
            props.listHistoriaVital();            
        }else{
            setIsSubmitting(false);
        }
        /*const salida=await axios.post('http://localhost:3000/api/historiavital/addHistoriaVital', formData,{ headers: { 'token': localStorage.getItem('token') , onUploadProgress: function(progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            console.log(percentCompleted)
          }} });*/
        //console.log(salida);
    }

    const anadir=()=>{
        var input = document.createElement('input');
        input.setAttribute("multiple","multiple");
        input.type = 'file';

        input.onchange = e => { 
         var file = e.target.files;
         let items=[...archivos];
         for(let i=0;i<file.length;i++)
         {            
            if(file[i].type==="image/png" || file[i].type==="image/jpeg" || file[i].type==="video/mp4"){
              items.push(file[i]);   
            }           
                          
         }                          
         
         setArchivos(items);         
        }

        input.click();
    }
    
    const removeFile=(i)=>{
       let items=[...archivos];
       items.splice(i,1);
       setArchivos(items);
    }

    const removerMultimedia=(i)=>{
        //console.log("REMOVER ITEM "+i);
        //console.log(props.item.multimedia.splice(i,1));                              
        setRemover(multimedia.splice(i,1));
        setMultimedia([...multimedia]);
    }

    const eliminarHistoria=async ()=>{

        Swal.fire({
            title: '¿Estás segur@ de eliminar el hito?',
            text: 'Ten en cuenta que los datos no podran ser recuperados',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: "Cancelar"
          }).then((result) => {
            if (result.value) {
                axios({
                    method: "delete",
                    headers: {                
                        'token': localStorage.getItem('token')
                    },
                    data: {id:props.item.id},
                    url: global.url+'historiavital/deleteHistoriaVital',
                    onUploadProgress: (ev) => {
                        const progress = ev.loaded / ev.total * 100;
                        //updateUploadProgress(Math.round(progress));
                        console.log(progress);
                    },
                }).then(data=>{
                    console.log(data);
                    props.onClick();                
                    props.listHistoriaVital();                   
                }).catch(err=>{
                    Swal.fire({
                        title: 'Error!',
                        text: 'La historia vital no pudo ser eliminada',
                        icon: 'error',
                        confirmButtonText: 'Sí'
                      })
                });                       
             
            }
          });        
    }

    
    return (
        <Row style={{ paddingBottom: 15 }}>
            <Col className="d-flex justify-content-start">
                <div className="anadirHitos">
                    <Row style={{ marginBottom: 30 }}><Col xs={11} md={11}><span className="h5">Añadir punto en la historia</span></Col><Col xs={1} md={1}><span className="closeX" onClick={props.onClick} >X</span></Col></Row>
                    <Row className="fila">
                        <Col xs={12} md={5}>
                            Especifica el año o la época
              </Col>
                        <Col xs={12} md={7}>
                            <textarea placeholder="Año" onChange={(value)=>setEpoca(value.target.value)} className="form-control">
                            {epoca}
                            </textarea>
                        </Col>
                    </Row>
                    <Row className="fila">
                        <Col xs={12} md={5}>
                            Explica que cosas ocurrieron, ¿por qué recuerdas esta fecha?, ¿qué fue importante?
              </Col>
                        <Col xs={12} md={7}>
                            <textarea placeholder="Historia" onChange={(value)=>setHistoria(value.target.value)}  className="form-control">
                            {historia}
                            </textarea>
                        </Col>
                    </Row>
                    <Row className="fila">
                        <Col xs={12} md={5}>
                            Si quieres puedes añadir vídeos o fotografías del momento. Pulsa el botón añadir
              </Col>
                        <Col xs={12} md={7} >
                            <div style={{ backgroundColor: "#f6f7fd", height: "4rem", display: "flex",flexDirection:"column",alignItems: "center" }}>
                                <button type="button" onClick={anadir} className="botonBorde" >Añadir fotografías o vídeos</button>
                                <div style={{display:"flex",paddingTop:10,paddingBottom:20}}>
                                {
                                    
                                  multimedia!=undefined &&    
                                  multimedia.map((item,i)=>{
                                     return(
                                        <div style={{padding:5}}>
                                            {item.tipo==="FOTO" ?
                                                <img style={{height:80}} src={global.url+"multimedia/getFile?key="+item.srcVideo} />
                                                :
                                                <span>VIDEO</span>
                                            }
                                            <FontAwesomeIcon onClick={()=>removerMultimedia(i)}  style={{position:"relative"}} icon="times-circle"/>
                                        </div>
                                     )
                                  })

                                }
                                </div>
                            </div>                            
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div>
                            {
                                archivos.map((item,index)=>{
                                    return(<div key={index} style={{display:"flex"}}><span style={{flex:2}}>{item.name}</span><FontAwesomeIcon onClick={()=>removeFile(index)} style={{fontSize:29,paddingLeft:10,cursor:"pointer"}} icon="minus-circle" /></div>)
                                })
                            }
                        </div>
                        </Col>
                    </Row>
                    <Row className="fila">
                        <Col xs={12} md={3}></Col>
                        <Col xs={12} md={9}>
                            <button className="btn simpleBoton" disabled={isSubmitting} onClick={guardar} style={{ marginTop: 10 }} type="button">
                                Guardar
                            </button>

                            {props.item &&   
                            <div style={{display:"flex",justifyContent:"center",marginTop:20}}>
                                <span style={{color:"#d1574a",fontWeight:"bold"}} onClick={eliminarHistoria}>
                                    Eliminar
                                </span>
                            </div>}
                        </Col>
                    </Row>                    
                </div>

            </Col>
        </Row>
    );
}


export default NewItem;