import React from "react";
import "./FacturacionPagos.css";
import { LinkContainer } from "react-router-bootstrap";
import { Modal, Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Pago from '../pedido/Pago/Pago';
import axios from 'axios';
import config from '../../../config';
import Necesitas from "../components/Necesitas/Necesitas";
export default function FacturacionPagos(props) {


  const [facturacion, setFacturacion] = React.useState(true);
  const [valorPagar, setValorPagar] = React.useState(0);
  const [motivoPago, setMotivoPago] = React.useState('');
  const [conceptoPago, setConceptoPago] = React.useState('');
  const [pago, setPago] = React.useState(false);
  const [dataUser, setDataUser] = React.useState([]);
  const [dataPagos, setDataPagos] = React.useState([]);
  const [dataxPagar, setDataxPagar] = React.useState([]);
  const [showPagosPendientes, setShowPagosPendientes] = React.useState(false);
  const history = useHistory();

  //constantes para manejo de modal
  const [lgShow, setLgShow] = React.useState(false);
  // variable para mostrar mensaje al pie de página de ayuda e información de contacto
  let mensajeNecesitas = "¿Dudas?";


  React.useEffect(() => {
    props.setActiva("facturacionpagos");
    setDataUser(props.attributes);
    getPagos();
    getPagosxPagar();
  }, []);


  const getPagos = async () => {

    await axios({
      method: 'get',
      url: config.url + 'pagos/listPagos',
      headers: { token: localStorage.getItem('token') }
    })
      .then(response => {
        console.log(response.data.data);
        setDataPagos(response.data.data);



      })
      .catch(error => {
        console.log(error)
        if(error.response!==undefined){
          let status = error.response.status;
          if(status == 401){
            history.push('logout');
          }
        }
      });

  }

  const descargarFactura=async (concepto)=>{    
    try{
    const response=await axios({
      method: 'get',
      url: config.url + 'pagos/getFactura',
      headers: { token: localStorage.getItem('token') }
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', 'factura.pdf'); //or any other extension
             document.body.appendChild(link);
             link.click();
    }catch(e){
      
    }
    
  }


  const getPagosxPagar = async () => {

    await axios({
      method: 'get',
      url: config.url + 'pagos/porPagar',
      headers: { token: localStorage.getItem('token') }
    })
      .then(response => {
        console.log(response.data.data);
        setDataxPagar(response.data.data);



      })
      .catch(err => {
        console.log(err)
      });

  }


  

  const handleEfectuarPago2 = (e, motivo, concepto, valor) => {
    console.log(valor, 'valor')
    setFacturacion(false);
    setPago(true);
    setValorPagar(valor);
    setMotivoPago(motivo);
    setConceptoPago(concepto);
    

  }

  const cancelar = () => {
    setFacturacion(true);
    setPago(false);
  }

  const FacturacionSection = () => {

    return (


      <>
        <Container>
          <Row className="mt-5">
            <Col>
              <h3 className="text-center customTitle">Facturación y Pagos</h3>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={{ span: 10, offset: 1 }}>
              <h4>Pagos efectuados</h4>

              {dataPagos ?
                  <>
                    {
                      dataPagos.map((data, i) => {
                        return (
                            <Card className="mt-2" key={i}>
                              <Card.Body>
                                <Container>

                                  <Row >
                                    <Col lg={6}>
                                      <Form>
                                        <Form.Row>
                                          <Col lg={5}>
                                            <Form.Group>
                                              <label className="my-1 mr-2 text-left">{data.fecha}</label>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={7}>
                                            <Form.Group >
                                              {data.concepto.map((conceptoPago, i) => {
                                                return (
                                                  <>
                                                    <span className="my-1 mr-2 text-left">
                                                      {conceptoPago == 'extra1' && <strong>Copa de cava</strong>}
                                                      {conceptoPago == 'extra2' && <strong>Maquillaje profesional</strong>}
                                                      {conceptoPago == 'extra3' && <strong>Comida con el entrevistador y dos acompañantes</strong>}
                                                      {conceptoPago == 'extra4' && <strong>Proyección privada</strong>}
                                                      {conceptoPago == 'extra5' && <strong>Pack extra</strong>}
                                                    </span>
                                                    </>
                                                );
                                              })}
                                            </Form.Group>
                                          </Col>
                                        </Form.Row>
                                      </Form>
                                    </Col>
                                    <Col lg={6}>
                                      <Form>
                                        <Form.Row>
                                          <Col lg={4}>
                                            <Form.Group>
                                              <label className="my-1 mr-2 text-left">Total: <strong>{data.importe}€</strong></label>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={8}>
                                            <Form.Row style={{display:"block"}}>
                                              <Form.Group >
                                                <a  ><button onClick={() => setLgShow(true)} className="btn md-button-outlined" type="button" >Visualizar factura</button></a>
                                              </Form.Group>
                                            </Form.Row>
                                            <Form.Row style={{display:"block"}}>
                                              <Form.Group >
                                                <a href={config.url + 'pagos/getFactura/'+localStorage.getItem('token_1')+"/"+data.id}><button className="btn md-button-outlined" type="button" >Descargar factura</button></a>
                                              </Form.Group>
                                            </Form.Row>
                                          </Col>
                                        </Form.Row>
                                      </Form>
                                    </Col>
                                  </Row>
                                </Container>
                              </Card.Body>
                            </Card>

                        );


                      })
                    }
                    {/* Modal en donde se visualiza la factura de venta */}
                    <Modal
                      size="lg"
                      show={lgShow}
                      onHide={() => setLgShow(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg" style={{textAlign:"center"}}>
                          <span style={{fontFamily: 'libre-baskerville-italic'}}>Factura de venta</span>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p><span><strong>Identificación de pedido:</strong> 9638816961399038</span></p>
                        <span><strong>Facturado a:</strong></span>
                        <span>Daniel Garcés</span>
                        <span>Cra 11</span>
                        <span>27</span>
                        <span>Risaralda, 660001</span>
                        <span>Pereira</span>
                        <p><span>ID de usuario: </span><strong>171ef272-d418-4727-97aa-5528f2fec55b5528f2fec5</strong></p>
                        <table style={{width:"100%"}}>
                          <thead>
                            <tr style={{backgroundColor:"#484B59", color:"white"}}>
                              <th style={{textAlign:"center"}}>Fecha de compra</th>
                              <th style={{textAlign:"center"}}>Forma de pago</th>
                              <th style={{textAlign:"center"}}>Estado del pago</th>
                            </tr>
                          </thead >
                          <tbody>
                            <tr>
                              <td style={{textAlign:"center"}}>2020-07-07</td>
                              <td style={{textAlign:"center"}}>Transferencia bancaria</td>
                              <td style={{textAlign:"center"}}>Pagado</td>
                            </tr>
                          </tbody>
                          <tr style={{textAlign:"center"}}>
                            <th style={{paddingTop:"1rem",textAlign:"center", fontFamily: 'libre-baskerville-italic',fontSize:"1.3rem"}} colSpan="3">Productos o servicios facturados</th>
                          </tr>
                          <tr style={{backgroundColor:"#484B59", color:"white"}} >
                            <th style={{textAlign:"center"}}>Cantidad</th>
                            <th style={{textAlign:"center"}}>Descripción</th>
                            <th style={{textAlign:"center"}}>Monto</th>
                          </tr>
                          <tr style={{border:"1px gray solid"}}>
                            <td style={{textAlign:"center", border:"1px gray solid"}}>1</td>
                            <td style={{border:"1px gray solid"}} >programada la fecha para la entrevista, extra2</td>
                            <td style={{textAlign:"right"}}>355.5 €</td>
                          </tr>
                          <tr>
                            <th colSpan="2" style={{textAlign:"right",border:"1px solid gray"}}>Impuesto 21%</th>
                            <td style={{textAlign:"right"}}>94.5 €</td>
                          </tr>
                          <tr>
                            <th colSpan="2" style={{textAlign:"right", border:"1px solid gray"}}>Total</th>
                            <td style={{textAlign:"right"}}><strong>450 €</strong></td>
                          </tr>
                        </table>
                      </Modal.Body>
                    </Modal>
                  </>
                  : null}


            </Col>
          </Row>
          {/**/}
          {dataxPagar ?
              <Row className="mt-5">
                <Col lg={{ span: 10, offset: 1 }}>
                  <h4>Próximos pagos</h4>
                  {dataxPagar.map((pagos, i) => {
                    var precio = 0;
                    if(pagos == 'programada la fecha para la entrevista'){
                      precio = 250;
                    }
                    if(pagos == 'reserva de tu Almadentro'){
                      precio = 250;
                    }
                    if(pagos == 'entrega'){
                      precio = 500;
                    }
                    return (

                        <>
                          <Card key={i} >
                            <Card.Body>
                              <Container>
                                <Row>
                                  <Col lg={6}>
                                    <Form>
                                      <Form.Row>
                                        <Col lg={5}>
                                          <Form.Group>
                                          </Form.Group>
                                        </Col>
                                        <Col lg={7}>
                                          <Form.Group >
                                            <span className="my-1 mr-2 text-left"><strong>{pagos}</strong></span>
                                          </Form.Group>
                                        </Col>
                                      </Form.Row>
                                    </Form>
                                  </Col>
                                  <Col lg={6}>
                                    <Form>
                                      <Form.Row>
                                        <Col lg={4}>
                                          <Form.Group>
                                            <label className="my-1 mr-2 text-left">Total: {precio}€<strong>
                                            </strong></label>
                                          </Form.Group>
                                        </Col>
                                        <Col lg={8}>
                                          <Form.Group >
                                            <button type="button" onClick={
                                              (e) =>
                                              { handleEfectuarPago2(e, pagos, pagos, precio) }} className="btn md-button-solid">Efectuar pago</button>
                                          </Form.Group>
                                        </Col>
                                      </Form.Row>
                                    </Form>
                                  </Col>
                                </Row>
                              </Container>
                            </Card.Body>
                          </Card>



                        </>
                    )

                  })}

                </Col>
              </Row>
              : null}

          {/**/}

        </Container>
        <Necesitas mensajeNecesitas={mensajeNecesitas} />
        </>

    );

  }


  return (
    <div>
      {facturacion && <FacturacionSection />}
      {pago && <Pago
        origen={'facturacionPagos'}
        motivoPago={motivoPago}
        valorPago={valorPagar}
        cancelarPago={cancelar}
        dataUser={dataUser}
        conceptoPago={conceptoPago}
      />
      }
    </div>

  );


}