import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import "./assets/fonts/open_sans/OpenSans.woff";
import {DndProvider} from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { Amplify } from 'aws-amplify';
import config from './config';
import {Provider} from "react-redux";
import store from "./redux/store";
import { CognitoUserPool } from 'amazon-cognito-identity-js';
/*import store from "./redux/store";
import {Provider} from "react-redux";*/

//pk_test_MlWCEIUduJf7rxGwGm4ULVMy00yZnHxuF6



Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
   
});


ReactDOM.render(
  <Router>
    <Provider store={store}>
     <DndProvider backend={Backend}>
      <App />
     </DndProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
