import React, {useEffect, useState} from "react";
import { Auth } from "aws-amplify";
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import { CognitoUser } from "amazon-cognito-identity-js";
import config from "../../../config";
import { useHistory } from "react-router-dom";
import Pool from "../../../UserPool";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Formik} from "formik";
import './RecuperarClave.css';
import Necesitas from "../components/Necesitas/Necesitas";

export default  function RecuperarClave() {


    const history = useHistory();


    const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);


    const getUser = () => {
        console.log(email, 'mostrando email');
        return new CognitoUser({
            Username: email.toLowerCase(),
            Pool
        });
    };

    const sendCode = event => {
        setIsDisabled(true);
        event.preventDefault();

        getUser().forgotPassword({
            onSuccess: data => {
                setIsDisabled(false);
                console.log("onSuccess:", data);
            },
            onFailure: err => {
                setIsDisabled(false);
                console.error("onFailure:", err);
            },
            inputVerificationCode: data => {
                console.log("Input code:", data);
                setStage(2);
            }
        });
    };

    const resetPassword = event => {
        setIsDisabled(true);


        if (password !== confirmPassword) {
            console.error("Passwords are not the same");
            toast.error('Las contraseñas no son las mismas');
            setIsDisabled(false);
            return;
        }

        getUser().confirmPassword(code, password, {
            onSuccess: data => {
                setIsDisabled(false);
                console.log("onSuccess:", data);
                toast.success('Contraseña cambiada exitosamente');
                history.push('/logout');
            },
            onFailure: err => {
                console.error("onFailure:", err);
            }
        });
    };



    const handleChange = (event) => {

        console.log(event);

    }

    return(
        <>
        <div className="contenidoRecuperar" >
            <ToastContainer />
            <Row className="mt-5">
                <Col>
                    <h3 className="text-center customTitle">Recuperar contraseña</h3>
                </Col>
            </Row>
            {stage === 1 &&
                <>
                    <Row className="mt-2" style={{marginBottom:"10rem"}}>
                        <Col lg={{span:4, offset:4}}>
                            <Card>
                                <Card.Body>
                                    <Container>
                                        <Row>
                                            <Col style={{display:"flex",justifyContent:"center"}}>
                                                <Form.Group >
                                                    <input type="text"
                                                           name="Email"
                                                           onChange={  (event) => { setEmail(event.target.value);setIsDisabled(false)}  }
                                                           className="form-control customInput"
                                                           placeholder="Correo electrónico"
                                                           style={{width:"250px"}}
                                                           />
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col style={{display:"flex",justifyContent:"center"}}>
                                                <button style={{width:"250px"}} onClick={sendCode} disabled={isDisabled} className="btn md-button-solid" >Enviar código de verificación</button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Card>
                        </Col>
                        
                    </Row>
                    
                </>

            }

            {stage === 2 &&
            <>
                <Row className="mt-2">
                    <Col lg={{span:6, offset:3}}>
                        <Card>
                            <Card.Body>
                                <Container>
                                    <Formik
                                        initialValues={{ code: '' }}
                                        validate={values => {
                                            const errors = {};

                                                if(!values.password){
                                                    errors.password='Requerido';
                                                }else if(
                                                    //!/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(values.password) 
                                                    !/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*).*$/.test(values.password)
                                                ){
                                                    errors.password='La contraseña debe tener entre 8 y 15 carácteres, una letra minúscula, una letra mayúscula, un número y un carácter especial';
                                                }

                                            return errors;
                                        }
                                        }
                                        onSubmit={(values, { setIsSubmitting }) => {
                                            console.log(code);
                                            console.log(password);
                                            console.log(confirmPassword);
                                            //return;
                                            resetPassword();
                                        }}
                                    >
                                        {({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              handleBlur,
                                              handleSubmit,
                                              isDisabled,
                                              /* and other goodies */
                                          }) => (
                                            <Form onSubmit={handleSubmit}>

                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <input type="text"
                                                                   name="code"
                                                                   value={values.code || ''}
                                                                   onChange= {(e) => { handleChange(e); setCode(e.target.value)  }}
                                                                   onBlur={handleBlur}
                                                                   className="form-control customInput"
                                                                   placeholder="Ingrese el código de verificación"/>
                                                                    {errors.code && touched.code && errors.code}
                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <input type="password"
                                                                   name="password"
                                                                   value={values.password || ''}
                                                                   onChange= {(e) => { handleChange(e); setPassword(e.target.value)  }}
                                                                   onBlur={handleBlur}
                                                                   className="form-control customInput"
                                                                   placeholder="Escriba la nueva contraseña"/>
                                                                   {errors.password && touched.password && errors.password}
                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <input type="password"
                                                                   name="confirmPassword"
                                                                   value={values.confirmPassword || ''}
                                                                   onChange= {(e) => { handleChange(e); setConfirmPassword(e.target.value)  }}
                                                                   onBlur={handleBlur}

                                                                   className="form-control customInput"
                                                                   placeholder="Confirme la nueva contraseña"/>
                                                                   {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}

                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <button  type="submit" disabled={isDisabled} className="btn md-button-solid">Cambiar Contraseña</button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </>

            }
            
        </div>
        <Necesitas style={{alignSelf:"flex-end", height:"83px"}} mensajeNecesitas="¿Dudas?" /> 
        </>
    )

}