import { createStore, combineReducers } from "redux";
//importando los reducers
import loginReducer from './reducers/loginReducer';
import tituloReducer from './reducers/tituloReducer';


const reducers = combineReducers({
  auth: loginReducer,
  pasos: tituloReducer,
  ui: ''
})

 const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
export default store;