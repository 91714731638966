import React from "react";
import Necesitas from "../../components/Necesitas/Necesitas";
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import Switch from '@material-ui/core/Switch';
import Configuracion from '../Configuracion';
import { Formik } from 'formik';
import axios from 'axios';
import global from '../../../../config';
import { useHistory } from "react-router-dom";
export default function NuevoDestinatario(props) {

    let { regresar, destinatarios,destinatario } = props;
    const history = useHistory();

    async function nuevoDestinatario(json) {
        console.log(json);
        if(destinatarios===undefined){
            destinatarios=[];
        }
        let params = destinatarios.push(json);
        let datos = {destinatarios: destinatarios} ;
        //console.log(datos);
        let endpoint="addDestinatarios";
        if(destinatario.data!==undefined){
            endpoint="updateDestinatario/"+destinatario.index;
            datos = {destinatarios: [json]} ;
        }
        await axios.post(global.url+'entrevistas/'+endpoint, datos, { headers: { 'token': localStorage.getItem('token') } })
          .then(response => {
              if(response.data.message == 'success'){
                regresar();
              }
            
          })
          .catch(error => {
            console.log(error);
            let status = error.response.status;
            if(status == 401){
            history.push('logout');
            }
          });
      }

    return (
        <>
            <Card>
                <Card.Body>
                    <Container>
                        <Formik 
                            initialValues={destinatario.data?destinatario.data:{nombre:""}}
                            validate={values => {
                                /* VALIDACIONES DE CAMPOS OBLIGATORIOS */
                                const errors = {};
                                if (!values.email) {
                                    errors.email = 'Correo electrónico obligatorio';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Dirección de correo electrónico no válida';
                                }
                                if (!values.nombre) {
                                    errors.nombre = 'Requerido';
                                }
                                if (!values.apellidos) {
                                    errors.apellidos = 'Requerido';
                                }
                                if (!values.telefono) {
                                    errors.telefono = 'Requerido';
                                }
                                if (!values.codigo_postal) {
                                    errors.codigo_postal = 'Requerido';
                                }
                                if (!values.provincia) {
                                    errors.provincia = 'Requerido';
                                }
                                if(!values.ciudad){
                                    errors.ciudad='Requerido';
                                }
                                if(!values.direccion){
                                    errors.direccion='Requerido';
                                }
                                if(!values.dni){
                                    errors.dni='Requerido';
                                }

                                
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                if (values.correo_postal){
                                    values.correo_postal = 'SI'
                                }else{
                                    values.correo_postal = 'NO'
                                }
                                console.log(values);
                                    
                                    nuevoDestinatario(values);
                                    setSubmitting(false);
                                
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                    /* CONTENIDO DEL FORMULARIO */

                                    <Form onSubmit={handleSubmit}>
                                        <Row className="p-2">
                                            <Col lg={12}><h3>{destinatario.data!==undefined?"Editar":"Nuevo"} destinatario</h3></Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <input type="text" name="nombre" onChange={handleChange} onBlur={handleBlur} value={values.nombre} className="form-control customInput" placeholder="Nombre" required/>
                                                    {errors.nombre && touched.nombre && errors.nombre}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <input type="text" name="apellidos" onChange={handleChange} onBlur={handleBlur} value={values.apellidos} className="form-control customInput" placeholder="Apellido" required/>
                                                    {errors.apellidos && touched.apellidos && errors.apellidos}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <input type="email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} className="form-control customInput" placeholder="Email" required/>
                                                    {errors.email && touched.email && errors.email}
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row className="p-2">
                                            <Col lg={1}>
                                                <Switch color="primary" name="correo_postal" onChange={handleChange} onBlur={handleBlur} value={values.correo_postal} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                            </Col>
                                            <Col lg={11}>
                                                <p><strong>Enviar por correo postal</strong></p>

                                            </Col>
                                        </Row>
                                        <Row className="p-2">
                                            <Col lg={6}>
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <Form.Control name="numero_cajas" onChange={handleChange} onBlur={handleBlur} value={values.numero_cajas} className="customInput" as="select" required>
                                                        <option value="">Seleccionar Opción</option>
                                                        <option value="2">2 cajas Almadentro</option>
                                                    </Form.Control>
                                                    {errors.numero_cajas && touched.numero_cajas && errors.numero_cajas}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="p-2">
                                            <Col lg={12}>
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <input type="text" name="direccion" onChange={handleChange} onBlur={handleBlur} value={values.direccion} className="form-control customInput" placeholder="Dirección" required/>
                                                    {errors.direccion && touched.direccion && errors.direccion}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <input type="text" name="provincia" onChange={handleChange} onBlur={handleBlur} value={values.provincia} className="form-control customInput" placeholder="Provincia" required/>
                                                    {errors.provincia && touched.provincia && errors.provincia}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <input type="text" name="ciudad" onChange={handleChange} onBlur={handleBlur} value={values.ciudad} className="form-control customInput" placeholder="Ciudad" required/>
                                                    {errors.ciudad && touched.ciudad && errors.ciudad}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <input type="text" name="codigo_postal" onChange={handleChange} onBlur={handleBlur} value={values.codigo_postal} className="form-control customInput" placeholder="Código Postal" required/>
                                                    {errors.codigo_postal && touched.codigo_postal && errors.codigo_postal}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <input type="text" name="dni" onChange={handleChange} onBlur={handleBlur} value={values.dni}  className="form-control customInput" placeholder="DNI" />
                                                    {errors.dni && touched.dni && errors.dni}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <input type="text" name="telefono" onChange={handleChange} onBlur={handleBlur} value={values.telefono} className="form-control customInput" placeholder="Teléfono" required/>
                                                    {errors.telefono && touched.telefono && errors.telefono}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4}>
                                                <button type="submit" disabled={isSubmitting} className="btn lg-button-solid" >{destinatario.data!==undefined?"Editar":"Añadir"} destinatario</button>
                                            </Col>
                                            <Col lg={4}>
                                                <button type="button" className="btn lg-button-solid" onClick={regresar}>Cancelar</button>
                                            </Col>
                                        </Row>
                                    </Form>

                                    /* FIN CONTENIDO DEL FORMULARIO */
                                )}
                        </Formik>

                    </Container>

                </Card.Body>
            </Card>

        </>



    );


}
