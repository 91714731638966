import React from "react";
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import config from '../../../../config';
import {connect} from "react-redux";

const Confirmacion=(props)=> {

    

    const {
        dataExtras,
        origen,
        motivoPago,
        valorPago,
        metodoPago,
        dataUser,
        transaccion,
        codigo,
        valorDescuento
    } = props;
    console.log(props);
    console.log(metodoPago , 'mostrando método de pago');
    const history = useHistory();

    
    const extras = () => {
        if(origen == 'reserva' || origen == 'configuracion'){
            
        }
    }
    
    

    return (
        <div>
            <Container className="p-5">
                <Row className="mt-5">
                    <Col>
                        <h3 className="text-center customTitle">¡Gracias por hacer tu pedido! {metodoPago}</h3>
                        {metodoPago == 'transferencia' &&
                        <p className="text-center" >Pago pendiente por comprobar. Una vez recibida la transferencia, recibirás un email con el justificante de la reserva.</p>
                        }
                        {metodoPago == 'tarjeta' &&
                        <p className="text-center" >Recibirás un email con el justificante de tu reserva.</p>
                        }
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col >
                        <Card>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col lg={5}>
                                            <Form.Group>
                                                <h5><strong>Mis Datos</strong></h5>
                                                <p>{dataUser.name} {dataUser.family_name}<br/>
                                                {dataUser.email}</p>
                                            </Form.Group>
                                            <Form.Group>
                                                <p>{dataUser.address}<br/>
                                                {dataUser['custom:codigo_postal']} {dataUser['custom:provincia']}<br/>
                                                {dataUser.phone_number}</p>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={5}>
                                            <Form.Group>
                                                <h5><strong>Resumen de tu pedido</strong></h5>
                                            </Form.Group>
                                            <Container>
                                            {origen == 'reserva' || origen == 'configuracion' ?
                                            <>
                                            {origen == 'reserva' ?
                                            <>
                                            <Row>
                                                <Col>Tu Almadentro</Col>
                                                <Col>{valorPago+"€"}</Col>
                                            </Row>
                                            {valorDescuento!=0 &&
                                            <Row>
                                                <Col>Descuento</Col>
                                                <Col>{valorDescuento}€</Col>
                                            </Row>
                                            }
                                            </>
                                             :
                                             null
                                            }
                                            
                                            {dataExtras.length > 0 && dataExtras.filter((filter)=>{return filter.incluir == 'SI'})
                                            .map((data)=>{
                                                return(
                                                <Row>
                                                    <Col><strong>Extra: </strong>{data.descripcion}</Col>
                                                    <Col>{data.precio ? data.precio+"€" : null}</Col>
                                                </Row>
                                                );
                                            })
                                            }
                                            </>
                                            :
                                            <Row>
                                                    <Col>{motivoPago}</Col>
                                                    <Col>{valorPago+"€"}</Col>
                                            </Row>
                                        }
                                                
                                            </Container>
                                        </Col>
                                        <Col lg={2}>
                                        {metodoPago == 'tarjeta' &&
                                        <a href={config.url + 'pagos/getFactura/'+localStorage.getItem('token_1')+"/null"}><button className="btn md-button-outlined">Imprimir</button></a>
                                        }
                                        
                                        </Col>
                                    </Row>

                                </Container>


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </div>

    );
}

const mapStateToProps=state=>({id:state.id});
const mapDispatchToProps=dispatch=>({
  ingresarPago(data){
      dispatch({
          type:"ingresarPago",
          data
      })
  }  
});

export default connect(mapStateToProps,mapDispatchToProps)(Confirmacion);