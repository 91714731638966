export default {
    s3: {
      REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
      BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
    },
    apiGateway: {
      REGION: "YOUR_API_GATEWAY_REGION",
      URL: "YOUR_API_GATEWAY_URL"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_Bf6E1sjNe",
      APP_CLIENT_ID: "1d2cnr99pc3pjrbm57u1mb663g",
      IDENTITY_POOL_ID: "us-east-1:1e2b84d8-2db4-4522-a8e1-a0b141cb44b9" 
    },
    cognitoAdmin: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_7x1eq5dgv",
      APP_CLIENT_ID: "5523o1s8ddppqdh74vljnd0den",
      IDENTITY_POOL_ID: "us-east-1:1e2b84d8-2db4-4522-a8e1-a0b141cb44b9" 
    },
    cognito_test: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-2_ib2CLqfMt",
      APP_CLIENT_ID: "2dpbdp32ndtf5f37v4lk3umrk7",
      IDENTITY_POOL_ID: "us-east-1:1e2b84d8-2db4-4522-a8e1-a0b141cb44b9"
    },
    stripe: {
      key: 'pk_test_MlWCEIUduJf7rxGwGm4ULVMy00yZnHxuF6"'
    },
    server:"localhost:4000",
    //url: "http://localhost:4000/api/",
    url: "https://api.vasco.vip/almadentro/api/",
  };