import React from "react";
import { Row, Col, Container, Button, ListGroup, Card, Form } from "react-bootstrap";
import './ui-kit.css';
export default function uiKit(){
 
    return (
        <Container fluid="md" className='mt-5'>
            <Row>
                <Col lg={12}>
                <div className="mt-5">
                <h3>UI KIT</h3>
                </div>
                </Col>
            </Row>
            <Row >
                <Col lg={3}>
                    <label>SOLID</label>
                    <Form.Group>
                    <button className="btn lg-button-solid">Large</button>    
                    </Form.Group>
                    <Form.Group>
                    <button className="btn md-button-solid">Medium</button>    
                    </Form.Group>
                    <Form.Group>
                    <button className="btn lg-button-solid">Hover Large</button>    
                    </Form.Group>
                    </Col>
                <Col lg={3}>
                    <label>OUTLINED</label>
                    <Form.Group>
                    <button className="btn lg-button-outlined">Large</button>    
                    </Form.Group>
                    <Form.Group>
                    <button className="btn md-button-outlined">Medium</button>    
                    </Form.Group>
                    <Form.Group>
                    <button className="btn lg-button-outlined">Hover</button>    
                    </Form.Group>
                </Col>
                <Col lg={3}>
                    <label>WARNING</label>
                    <Form.Group>
                    <button className="btn lg-button-warning">Large</button>    
                    </Form.Group>
                    <Form.Group>
                    <button className="btn md-button-warning">Medium</button>    
                    </Form.Group>
                    <Form.Group>
                    <button className="btn lg-button-warning">Hover</button>    
                    </Form.Group>
                </Col>
                <Col lg={3}>
                <label>LINKS</label>
                    <Row>
                        <Col>
                            <Form.Group>
                            <a href="" className="link-outlined">Eliminar</a>    
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                            <a href="" className="link-solid">Eliminar</a>    
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                            <a href="" className="link-outlined">Editar</a>    
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                            <a href="" className="link-solid">Editar</a>    
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );

}