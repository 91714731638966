import React from "react";
import './detalles.css';
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import Complete from '../../../../assets/img/complete.svg';
import axios from "axios";
import global from '../../../../config';
import { useHistory } from "react-router-dom";


export default function DetallesDashboard(props) {

    const listaPasos_s=["reserva","reserva_entrevista","grabacion","trailer_disponible","entrega","enviado"];
    const listaPasosTestamento_s=["reserva","reserva_entrevista","grabacion","trailer_disponible","entrega"];
   

    const listaPasos=[{name:"reserva",title:"Completa la historia vital"},{name:"reserva_entrevista",title:"Programada la fecha para la entrevista"},{name:"grabacion",title:"Grabación"},{name:"trailer_disponible",title:"Trailer disponible"},{name:"entrega",title:"Entrega"},{name:"enviado",title:"Enviado"}];
    const listaPasosTestamento=[{name:"reserva",title:"Completa la historia vital"},{name:"reserva_entrevista",title:"Programada la fecha para la entrevista"},{name:"grabacion",title:"Grabación"},{name:"trailer_disponible",title:"Trailer disponible"},{name:"entrega",title:"Depositado ante notario"}];
    const [fechaPasos,setFechaPasos]=React.useState({});
    const item=props.history.location.state.item;
        
    //console.log(props.history.location.state.item);

    React.useEffect(()=>{        
        getDetalles();        
    },[]);


    const [detalles,setDetalles]=React.useState({pasos:[]});

    const getUltimo=(simple,keys,data)=>{        
        for(let i=simple.length-1;i>0;i--){
           console.log("JUAS");
           if(keys.indexOf(simple[i])!=-1){                       
               return simple[i];
           }
        }
      };

    function getDetalles() {               
        axios({
            method: 'get',
            url: global.url+'configuraciones/detallePedido/'+item.cognitoID,                        
          }).then(response => {
            //console.log(response);  
            let data=response.data.data;
            const keys=Object.keys(data.pasos_realizados);                                                
            //keys[keys.length-1];           
            data.tipo=data.testamento==="SI"?"TESTAMENTO":data.este_listo==="SI"?"ESTE LISTO":"";  
            data.pasos=data.tipo==="TESTAMENTO"?listaPasosTestamento:listaPasos;
            let simple=data.tipo==="TESTAMENTO"?listaPasosTestamento_s:listaPasos_s;
            data.ultimo=getUltimo(simple,keys,data);
            setDetalles(data);            
          })
          .catch(error => {
            console.log(error);
          });
        
      }

      const getPasos=(item)=>{
        const keys=Object.keys(detalles.pasos_realizados);                          
        return keys.indexOf(item)>-1;
      }


      const guardar=()=>{
        axios({
            method: 'post',
            url: global.url+'entrevistas/addPaso',                        
            data:{cognitoID:item.cognitoID,paso:fechaPasos}
          }).then(response => {
            if(response.data.status==="200"){
               getDetalles();
            }
          });
      }

    

    return (

        <div>
            <Container className="py-5">
                <Row className="mt-5">
                    <Col lg={6}>
                        <Card>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col>
                                            <h4 className="customTitle">Detalles del pedido</h4>
                                            <p>ID: {item.id}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5><strong>Datos del cliente</strong></h5>
                                            <br />
                                            <Form.Group>
                                                <span>{item.user.name} {item.user.family_name}</span>
                                                <span>{item.user.email}</span>
                                            </Form.Group>
                                            <Form.Group>
                                                <span>{item.user.direccion}</span>
                                                <span>{item.user.ciudad} {item.user.provincia}</span>
                                                <span>{item.user.telefono}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <h5><strong>Resumen del pedido</strong></h5>
                                            <br />
                                            {
                                                item.concepto.map(item=>{
                                                  return (                                                    
                                                    <p>{item}</p>                                                                                                        
                                                  )
                                                })
                                            }                                            
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col lg={8}>
                                                            <small className="text-muted text-left">FECHA DEL PEDIDO</small>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <p className="text-right">{item.fecha}</p>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col lg={8}>
                                                            <small className="text-muted text-left">PASO ACTUAL</small>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <p className="text-right">{detalles.ultimo}</p>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col lg={8}>
                                                            <small className="text-muted text-left">ESTADO</small>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <p className="text-right">Pagado</p>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col lg={8}>
                                                            <small className="text-muted text-left">TIPO DE ENTREGA</small>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <p className="text-right">{detalles.tipo}</p>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                    </Row>


                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col>
                                            <h4 className="customTitle">Cronograma</h4>

                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Container>
                                            
                                            {/* <Row className="py-3">
                                                <Col lg={5}>
                                                    <h5><strong>Historial Vital</strong></h5>
                                                </Col>
                                                <Col lg={7}>
                                                 {detalles.temas_a_tratar!=null ?
                                                   <><img src={Complete}/> <label>Completada</label></>
                                                   :
                                                   <><img src={Complete}/> <label>Incompleta</label></>
                                                 }
                                                </Col>
                                            </Row> */}
                                            
                                            <hr/>
                                            {detalles.pasos.map(item=>{
                                               if(getPasos(item.name)){
                                                return(
                                                <>    
                                                <Row className="py-3">
                                                <Col lg={5}>
                                                  <h5><strong>{item.title}</strong></h5>
                                                </Col>
                                                <Col lg={7}>
                                                <img src={Complete}/> <label>Completada, {detalles.pasos_realizados[item.name]}</label>
                                                {item==="entrega" &&     
                                                    <Form.Group>
                                                        <button className="btn md-button-outlined">Descargar direcciones de entrega</button>
                                                    </Form.Group>}
                                                </Col>
                                               </Row>
                                               <hr/>
                                               </>)
                                               }else{
                                                   return(
                                                      <>
                                                      <Row className="py-3">
                                                        <Col lg={4}>
                                                            <h5><strong>{item.title}</strong></h5>
                                                        </Col>
                                                        <Col lg={8}>
                                                        <Form.Group>
                                                            <input type="date"  onChange={(i)=>setFechaPasos({...fechaPasos,[item.name]:i.target.value})} className="form-control"/>
                                                        </Form.Group>
                                                        {item.name==="entrega" &&     
                                                            <Form.Group>
                                                                <button className="btn md-button-outlined">Descargar direcciones de entrega</button>
                                                            </Form.Group>}                                                        
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    </> 
                                                   )
                                               } 
                                            })}
                                                                                                                                   
                                            <Row>
                                                <Col lg={{span: 8, offset:4}}>
                                                    <button className="btn btn-block lg-button-solid" onClick={guardar}>Guardar Cambios</button>
                                                </Col>
                                            </Row>
                                        </Container>


                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

    );

}