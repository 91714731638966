import React, { Component } from "react";
import Necesitas from "../components/Necesitas/Necesitas";
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-bootstrap';
import "./Configuracion.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Miniatura from '../../../assets/img/miniatura.jpeg';
import Switch from '@material-ui/core/Switch';
import NuevoDestinatario from './nuevo-destinatario/nuevo-destinatario';
import ModificarUsuario from "./misdatos/misdatos";
import data from "../../../config";
import axios from 'axios';
import Swal from 'sweetalert2';
import Pago from '../pedido/Pago/Pago';
import { useHistory } from "react-router-dom";
import global from '../../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Configuracion(props) {


  console.log(props, 'mostrando props');

  const [dataExtras, setDataExtras] = React.useState([]);
  const [dataExtrasNotApproved, setDataExtrasNotApproved] = React.useState([]);
  const [valorExtras, setValorExtras] = React.useState(0);
  const [destinatario, setDestinatario] = React.useState(false);
  const [destinatarios, setDestinatarios] = React.useState([]);
  const [data_destinatarios, setDataDestinatarios] = React.useState({});
  const [dataConfiguracion, setDataConfiguracion] = React.useState([]);
  const [entregaAlmadentro, setEntregaAlmadentro] = React.useState();
  const [estiloAlmadentro, setEstiloAlmadentro] = React.useState();
  const [fechaAlmadentro, setFechaAlmadentro] = React.useState('');
  const [privacidadAlmadentro, setPrivacidadAlmadentro] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isModificar, setModificar] = React.useState(false);
  const [configuracion, setConfiguracion] = React.useState(true);
  const [valorPagar, setValorPagar] = React.useState(0);
  const [motivoPago, setMotivoPago] = React.useState('');
  const [pago, setPago] = React.useState(false);
  const [btnPagar, setBtnPagar] = React.useState(false);
  const [dataUser, setDataUser] = React.useState([]);
  const [pagoRegistrado, setPagoRegistrado] = React.useState(false);


  React.useEffect(() => {
    props.setActiva("configuracion");
    comprobarPago();
  },[]);
  const history = useHistory();
  //variable - //callback

  async function comprobarPago() {
    await axios({
      method: 'get',
      url: global.url + 'pagos/listPagos',
      headers: { token: localStorage.getItem('token') }
    })
    .then(response => {
        //alert(response);
        console.log(response, 'mostrando response');
        if(response.data.data.length > 0){
          setPagoRegistrado(true);
          //redirect="/tualmadentro"; 
        }else{
          history.push('facturacionpagos');
          //redirect="/facturacionpagos";
        }
    })
    .catch(error => {
      console.log(error)
    });
  }

  React.useEffect(()=>{
    if(pagoRegistrado){
      getExtras();
      getDestinatarios();
      getConfiguracion();
      setDataUser(props.attributes);
    }
    
  },[pagoRegistrado])


  //componentes funcionales
  const handleClickNuevoDestinatario = () => {
    setDataDestinatarios({});
    setDestinatario(true)
  }

  async function getExtras() {
    await axios.get(global.url+'entrevistas/listExtrasEntrevista', { headers: { 'token': localStorage.getItem('token') } })
      .then(response => {
        setDataExtras(response.data.data.approved)
        setDataExtrasNotApproved(response.data.data.notapproved)
      })
      .catch(error => {
        console.log(error);
      });
  }

  async function getConfiguracion() {
    await axios.get(global.url+'entrevistas/listPublicacion', { headers: { 'token': localStorage.getItem('token') } })
      .then(response => {
        console.log(response);
        let data = response.data.data;

        if(data.testamento!=undefined)
        {
          if(data.testamento == 'SI'){
            setEntregaAlmadentro('testamento')
          }
        
          if(data.testamento == 'NO'){
            setEntregaAlmadentro('fecha')
          }
        }

        if(data.este_listo!=undefined)
        {
          if(data.este_listo == 'SI'){
            setEntregaAlmadentro('este_listo')
          }
          if(data.este_listo == 'NO' && data.testamento == 'NO'){
            setEntregaAlmadentro('fecha')
            setFechaAlmadentro(data.fecha_entrega)
          }
        }

        if(data.privacidad!=undefined)
        {
          if(data.privacidad == 'PUBLICO'){
            setPrivacidadAlmadentro(false);
          }
          if(data.privacidad == 'PRIVADO'){
            setPrivacidadAlmadentro(true);
          }
        if (data.testamento == 'SI') {
          setEntregaAlmadentro('testamento')
        }
        if (data.testamento == 'NO') {
          setEntregaAlmadentro('fecha')
        }
        if (data.este_listo == 'SI') {
          setEntregaAlmadentro('este_listo')
        }
        if (data.este_listo == 'NO' && data.testamento == 'NO') {
          setEntregaAlmadentro('fecha')
          setFechaAlmadentro(data.fecha_entrega)
        }
        if (data.privacidad == 'PUBLICO') {
          setPrivacidadAlmadentro(false);
        }
        if (data.privacidad == 'PRIVADO') {
          setPrivacidadAlmadentro(true);
        }
        setEstiloAlmadentro(data.estilo);

        setDataConfiguracion(data);

      }
    })
      .catch(error => {
        
        if(error.response!==undefined){
        let status = error.response.status;
        if(status == 401){
          history.push('logout');
        }
      }
        
      });
  }

  async function updateConfiguracion(data) {
    await axios.post(global.url+'entrevistas/addPublicacion', data, { headers: { 'token': localStorage.getItem('token') } })
      .then(response => {
        console.log(response);
        let data = response.data.data;
        setIsSubmitting(false);
        toast.success("Configuración guardada exitosamente");
        getConfiguracion();
        console.log(data);

      })
      .catch(error => {
        toast.error("No se pudo guardar la configuración");
        console.log(error);
      });
  }


  async function getDestinatarios() {
    await axios.get(global.url+'entrevistas/listDestinatarios', { headers: { 'token': localStorage.getItem('token') } })
      .then(response => {
        console.log(response);
        setDestinatarios(response.data.data.destinatarios)
      })
      .catch(error => {
        console.log(error);
      });
  }



  const handleChangeEntregaAlmadentro = (e) => {
    let data = e.target.value;
    setEntregaAlmadentro(data);
  }

  const handleChangeEstiloAlmadentro = (e) => {
    let data = e.target.value;
    setEstiloAlmadentro(data);

  }

  const handleChangePrivacidad = (e) => {
    setPrivacidadAlmadentro((prev) => !prev);

  }


  const handleAddExtras = (event, precio, index) => {
    let check = event.target.checked;
    let sumarValor = valorExtras + precio;
    let restarValor = valorExtras - precio;
    setMotivoPago('Extras');
    console.log(sumarValor);
    setValorExtras(sumarValor);
    dataExtrasNotApproved[index].incluir = 'SI';
    setDataExtrasNotApproved([...dataExtrasNotApproved]);
    console.log(dataExtrasNotApproved);
    //setPago(true);
    setBtnPagar(true);
  }

  const handlePagar = () => {
    setPago(true);
  }

  const cancelar = () => {
    setPago(false);
  }

  const editarDestinatario = (data, i) => {
    setDataDestinatarios({ index: i, data: data });
    //console.log(data);
    setDestinatario(true);
  }

  const eliminarCuenta = () => {
    Swal.fire({
      title: '¿Estás segur@ de eliminar la cuenta?',
      text: 'Ten en cuenta que los datos no podran ser recuperados',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.value) {

      }
    });

  }

  const eliminarDestinatario = (i) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará el destinatario seleccionado',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.value) {
        axios.delete(global.url+'entrevistas/deleteDestinatario/' + i, { headers: { 'token': localStorage.getItem('token') } })
          .then(response => {
            if (response.status == "200") {
              let temp_destinatarios = [...destinatarios];
              temp_destinatarios.splice(i, 1);
              setDestinatarios(temp_destinatarios);
            } else {
              Swal.fire({
                title: 'Error!',
                text: 'El destinatario no pudo ser eliminado',
                icon: 'error',
                confirmButtonText: 'Sí'
              })
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    });

  }

  const enviar = () => {
    setIsSubmitting(true);
    let fecha = '';
    let testamento = '';
    let este_listo = '';
    let privacidad = '';
    if (entregaAlmadentro == 'testamento') { testamento = 'SI'; fecha = ''; este_listo = 'NO' }
    if (entregaAlmadentro == 'fecha') { testamento = 'NO'; este_listo = 'NO'; fecha = fechaAlmadentro; }
    if (entregaAlmadentro == 'este_listo') { testamento = 'NO'; fecha = ''; este_listo = 'SI' }
    if (privacidadAlmadentro) { privacidad = 'PRIVADO' }
    if (!privacidadAlmadentro) { privacidad = 'PUBLICO' }
    let params = {
      estilo: parseInt(estiloAlmadentro),
      privacidad: privacidad,
      testamento: testamento,
      fecha_entrega: fecha,
      este_listo: este_listo
    }
    updateConfiguracion(params);


  }



  const Configuracion = () => {



  }

  return (
    <>
      <ToastContainer />
      {!pago ?
        <>
          <Container fluid="lg" className='mt-5'>
            <Row className="">
              <Col lg={12}>
                <h1 className="customTitle">Configuración</h1>
              </Col>
            </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="publicacion">
              <Row className="mt-5">
                <Col lg={2}>
                  <Nav variant="" className="flex-column withoutBackground">
                    <Nav.Item>
                      <Nav.Link eventKey="publicacion">Publicación</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="entrega">Entrega</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="misdatos">Mis Datos</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg={10}>
                  <Tab.Content>
                    <Tab.Pane eventKey="publicacion">
                      {/*PUBLICACION TAB*/}
                      <Container>
                        <Row>
                          <Col>
                            <Card>
                              <Card.Body>
                                <Form onSubmit={enviar}>
                                  <h3>¿Cuándo quieres que se entregue tu Almadentro?</h3>
                                  <Row className="mt-4">
                                    <Col lg={6}>
                                      <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Control name="entrega_almadentro" onChange={handleChangeEntregaAlmadentro} value={entregaAlmadentro} className="customInput" as="select">
                                          <option value="">Seleccionar una opción</option>
                                          <option value="fecha">Una fecha concreta</option>
                                          <option value="testamento">Testamento</option>
                                          <option value="este_listo">Cuando esté listo</option>
                                        </Form.Control>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  {entregaAlmadentro == 'fecha' &&
                                    <Row>
                                      <Col lg={6}>
                                        <InputGroup className="mb-3">
                                          <input type="date" name="fecha_almadentro" value={fechaAlmadentro} onChange={(e) => { setFechaAlmadentro(e.target.value) }} className="form-control customInput" />
                                        </InputGroup>
                                      </Col>
                                    </Row>
                                  }
                                  <hr className="divider" />
                                  <Row className="p-3">
                                    <Col lg={12}>
                                      <h4>Estilo de tu Almadentro</h4>
                                    </Col>
                                  </Row>
                                  <Row >

                                    <br />
                                    <Col lg={4}>
                                      <Card style={{ width: '15rem' }}>
                                        <Card.Body>
                                          <Card.Title>
                                            <Row>
                                              <Col lg={10}><label>Estilo 1</label></Col>
                                              <Col lg={2}><input name="estilo_almadentro" value={1} checked={estiloAlmadentro == 1} onChange={handleChangeEstiloAlmadentro} type="radio" /></Col>
                                            </Row>
                                          </Card.Title>
                                          <Card.Text>
                                            <img src={Miniatura} width={200}></img>
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                    <Col lg={4}>
                                      <Card style={{ width: '15rem' }}>
                                        <Card.Body>
                                          <Card.Title>
                                            <Row>
                                              <Col lg={10}><label>Estilo 2</label></Col>
                                              <Col lg={2}><input name="estilo_almadentro" value={2} checked={estiloAlmadentro == 2} onChange={handleChangeEstiloAlmadentro} type="radio" /></Col>
                                            </Row>
                                          </Card.Title>
                                          <Card.Text>
                                            <img src={Miniatura} width={200}></img>
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                    <Col lg={4}>
                                      <Card style={{ width: '15rem' }}>
                                        <Card.Body>
                                          <Card.Title>
                                            <Row>
                                              <Col lg={10}><label>Estilo 3</label></Col>
                                              <Col lg={2}><input name="estilo_almadentro" value={3} checked={estiloAlmadentro == 3} onChange={handleChangeEstiloAlmadentro} type="radio" /></Col>
                                            </Row>
                                          </Card.Title>
                                          <Card.Text>
                                            <img src={Miniatura} width={200}></img>
                                          </Card.Text>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <hr className="mt-5 divider" />
                                  <Row className="p-2">
                                    <Col lg={12}>
                                      <h4>Privacidad</h4>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={1}>
                                      <Switch color="primary" name="privacidad" checked={privacidadAlmadentro} onChange={handleChangePrivacidad} inputProps={{ 'aria-label': 'primary checkbox' }}
                                      />
                                    </Col>
                                    <Col lg={11}>
                                      <h4>Estado: {privacidadAlmadentro ? 'Privado' : 'Público'}</h4>
                                      <p>Si seleccionas público, autorizas a Almadentro a publicar <br /> contenido de tu entrevista en sus redes sociales y página web </p>
                                    </Col>

                                  </Row>
                                  <Row className="p-2">
                                    <Col lg={4}>
                                      <button type="button" onClick={enviar} disabled={isSubmitting} className="btn lg-button-solid">Guardar Configuración</button>
                                    </Col>
                                  </Row>
                                </Form>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Container>

                    </Tab.Pane>
                    {/*ENTREGA TAB*/}
                    <Tab.Pane eventKey="entrega">

                      {!destinatario
                        ? <div>
                          <Container>
                            <Row>
                              <Col>
                                <h3>Completa tu envío</h3>
                                <p>Añade extras para hacer tu Almadentro más especial</p>

                                {
                                  dataExtras.map((data, i) => {
                                    return (
                                      <div key={i}>
                                        <Card>
                                          <Card.Body>
                                            <Row>
                                              <Col lg={8}>
                                                <strong>{data.descripcion}</strong>
                                              </Col>
                                              <Col lg={1}>
                                                <label className="my-1 mr-2 label-solid text-left"><strong>Incluido</strong></label>
                                              </Col>
                                              <Col lg={3}>

                                              </Col>
                                            </Row>

                                          </Card.Body>
                                        </Card>
                                      </div>
                                    );
                                  }
                                  )
                                }
                                {
                                  dataExtrasNotApproved.map((data, i) => {
                                    return (
                                      <div key={i}>
                                        <Card>
                                          <Card.Body>
                                            <Row>
                                              <Col lg={8}>
                                                <strong>{data.descripcion}</strong>
                                              </Col>
                                              <Col lg={1}>
                                                {data.defecto == 'SI' &&
                                                  <>
                                                    <label className="my-1 mr-2 label-solid text-left"><strong>Incluido</strong></label>
                                                  </>
                                                }
                                                {data.precio != '' && data.defecto != 'SI' &&
                                                  <>
                                                    <label className="my-1 mr-2"><strong>+{data.precio}€</strong></label>
                                                  </>
                                                }
                                              </Col>
                                              <Col lg={3}>

                                                {data.incluir != 'SI' && data.defecto != 'SI' &&
                                                  <>
                                                    <button
                                                      type="button"
                                                      onClick={(e) => { handleAddExtras(e, data.precio, i) }}
                                                      className="btn md-button-solid">
                                                      Añadir Extra
                                        </button>
                                                  </>
                                                }
                                                {data.incluir == 'SI' &&
                                                  <>
                                                    <label className="my-1 mr-2 label-solid text-left"><strong>Añadido</strong></label>
                                                  </>
                                                }
                                              </Col>

                                            </Row>

                                          </Card.Body>
                                        </Card>
                                      </div>
                                    );
                                  }
                                  )
                                }
                              </Col>

                            </Row>
                            {btnPagar &&
                              <Row className="mt-2">
                                <Col lg={{ span: 3 }}>
                                  <button type="button" onClick={handlePagar} className="btn md-button-outlined">Pagar Ahora</button>
                                </Col>
                              </Row>
                            }
                          </Container>

                          <Container fluid="lg" className="mt-5">
                            <Row>
                              <Col>
                                <h3>¿Quién recibirá tu Almadentro?</h3>
                                <p>Tu Almadentro se entregará a las personas que añadas a esta lista.</p>
                              </Col>
                            </Row>

                            <Row>
                              {destinatarios &&
                                <>
                                  {
                                    destinatarios.map((data, i) => {
                                      return (
                                        <Col lg={6} key={i}>
                                          <Card>
                                            <Card.Body>
                                              <Container>
                                                <Row>
                                                  <Col lg={9}>
                                                    <strong>{data.nombre} {data.apellidos}</strong><br />
                                                    {data.direccion}<br />
                                                    {data.codigo_postal} {data.provincia}<br />
                                                  </Col>
                                                  <Col lg={3}>
                                                    <a href="#" onClick={() => editarDestinatario(data, i)} className="link-outlined">Editar</a>
                                                  </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                  <Col lg={9}>
                                                    {data.email}
                                                  </Col>
                                                  <Col lg={3}>
                                                    <a href="#" onClick={() => eliminarDestinatario(i)} className="link-outlined">Eliminar</a>
                                                  </Col>
                                                </Row>
                                              </Container>
                                            </Card.Body>
                                          </Card>
                                        </Col>
                                      );

                                    })
                                  }
                                </>



                              }
                            </Row>
                            <Row className="mt-5">
                              <Col lg={3}>
                                <button type="button" onClick={handleClickNuevoDestinatario} className="btn md-button-outlined">Añadir Más</button>
                              </Col>
                            </Row>


                          </Container>
                        </div>
                        :
                        <div>

                          <NuevoDestinatario destinatario={data_destinatarios} destinatarios={destinatarios} regresar={() => { setDestinatario(false); getDestinatarios(); }} />

                        </div>
                      }
                      {/*FIN ENTREGA TAB*/}
                    </Tab.Pane>
                    <Tab.Pane eventKey="misdatos">
                      {isModificar ? <ModificarUsuario setModificar={setModificar} setAttributes={props.setAttributes} attributes={props.attributes} /> :
                        <Container>
                          <Row>
                            <Col>
                              <h4>Mis datos de contacto</h4>
                              <p>{props.attributes.name + " " + props.attributes.family_name}<br />{props.attributes.email}<br />{props.attributes.phone_number}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <a href="#" onClick={() => setModificar(true)} className="link-outlined">Modificar</a>
                            </Col>
                          </Row>
                          <Row className="mt-5">
                            <Col lg={4}>
                              <button onClick={() => eliminarCuenta()} className="btn md-button-warning">Eliminar mi cuenta Almadentro</button>
                            </Col>
                          </Row>
                        </Container>
                      }
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>

            {/**/}

          </Container>
          <Necesitas mensajeNecesitas="¿Dudas?"/>
        </>
        : <Pago
          origen={'configuracion'}
          modificarpedido={cancelar}
          valorExtras={valorExtras}
          motivoPago={motivoPago}
          dataExtras={dataExtrasNotApproved}
          dataUser={dataUser} />

      }
    </>

  );

}