
import React, { Suspense } from "react";
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-bootstrap';
import "./tualmadentro.css";
import global from '../../../config';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Necesitas from "../components/Necesitas/Necesitas";

const PasosTuAlmadentro = React.lazy(() => import('./pasos-tu-almadentro/pasos'));
const CompletaHistoria = React.lazy(() => import('./Completa/Completa'));
const Entrevista = React.lazy(() => import('./entrevista/entrevista.js'));

export default function TuAlmadentro(props) {
  const [pasos, setPasos] = React.useState(true);
  const [Completar, setCompletar] = React.useState(false);
  const [Titulo, setTitulo] = React.useState("TU ALMADENTRO");
  const [subTitulo, setSubTitulo] = React.useState(
    "¡Hola María! Estos son los próximos pasos de tu Almadentro"
  ); 

  const history = useHistory();
  
  const [activeKey, setActiveKey] = React.useState('pasos-tu-almadentro');
  const [tituloHistoriaVital, setTituloHistoriaVital] = React.useState(null);
  
  React.useEffect(() => {   
    props.setActiva("tualmadentro");
    }, []);

  

  const completarHistoria = () => {
    props.history.push("completahistoria");
    /*setCompletar(true);
  setTitulo("Completa la historia vital");
  setSubTitulo("Unas preguntas antes de empezar");*/
  };

  /*PROBANDO ANDO */

  const Fecha = ({ children }) => {
    return (
      <div
        style={{
          display: "block",
          float: "left",
          color: "black",
        }}
        className="timeline-left"
      >
        {children}
      </div>
    );
  };

  return (
    <div>
      <Container className='mt-5'>
      <Row className="">
        <Col lg={12}>
          <h2 className="customTitle">Tu Almadentro</h2>
        </Col>
      </Row>
      <Tab.Container id="left-tabs-example" activeKey={activeKey} >
        <Row className="mt-5">
          <Col lg={3}>
            <Nav variant="" className="flex-column withoutBackground">
              <Nav.Item>
                <Nav.Link eventKey="pasos-tu-almadentro" onClick={(e)=> {e.preventDefault(); setActiveKey('pasos-tu-almadentro');}}>Pasos de tu Almadentro</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="historia-vital" onClick={(e)=> {e.preventDefault(); setActiveKey('historia-vital');}}>Historia Vital</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="entrevista" onClick={(e)=> {e.preventDefault(); setActiveKey('entrevista');}}>Entrevista</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col> 
          <Col lg={9}>
          <Tab.Content >
              <Tab.Pane eventKey="pasos-tu-almadentro">
              <Suspense fallback={<div>Loading...</div>}>
              <PasosTuAlmadentro setActiveKey={setActiveKey} setTituloHistoriaVital={setTituloHistoriaVital}/> 
              </Suspense>
                
              </Tab.Pane>
              <Tab.Pane eventKey="historia-vital" >
              <Suspense fallback={<div>Loading...</div>}>
              <CompletaHistoria setActiveKey={setActiveKey} titulo={tituloHistoriaVital}/>
              </Suspense>
                
              </Tab.Pane>
              <Tab.Pane eventKey="entrevista">
              <Suspense fallback={<div>Loading...</div>}>
              <Entrevista setActiveKey={setActiveKey}/>
                </Suspense>
                
              </Tab.Pane>
          </Tab.Content>
          </Col>
          </Row>
          </Tab.Container>
      </Container>
      <Necesitas mensajeNecesitas="¿Dudas?"/>
      </div>

  );
}
