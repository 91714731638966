import React, { createElement } from "react";
import "./hitos.css";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Necesitas from "../../components/Necesitas/Necesitas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnadirHitos from "../anadirHitos/anadirHitos"; 
import Item from "./item";
import {useDrop} from "react-dnd";
import update from 'immutability-helper'
import Plus from "../../../../assets/img/plus.png";
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import NewItem from "./newItem";
import axios from 'axios';
import global from "../../../../config";
export default function Hitos(props) {
    
    
    const[{isOver},drop]=useDrop({
        accept:"TEXTO",        
        drop:(item,monitor)=>{console.log(item)},
        collect:monitor=>({            
            isOver:!!monitor.isOver()
        })
    });

    const { historiaVital } = props;
   

    const [pitems, setPitems] = React.useState([]);
    const [addhistoria,setHistoria]=React.useState(false);

    /*const items = [
        { titulo: "Verano del 1990", contenido: "Es una epoca inexistente en el calendario, pero vivida en el corazón", fotos: "3 fotografías" },       
    ];*/

    

    React.useEffect(() => {
       listHistoriaVital();        
    }, []);    
    

    React.useEffect(() => {
        listHistoriaVital();        
     }, [addhistoria]);   
    

     const listHistoriaVital=()=>{
         console.log("CONSULTANDO DESDE HISTORIA VITAL");
        axios.get(global.url+"historiavital/listHistoriaVital", {headers: {                
            'token': localStorage.getItem('token')
        }}).then(data=>{           
            const salida=data.data.data.sort(function(a, b){return a.posicion-b.posicion});            
            setPitems(salida);
        }).catch(error=>{
            console.log(error);
        })
    }
    /*let previo=null;
    let actual=null;

    const dropfunction=(e)=>{
        e.preventDefault();
        console.log("SOLTAR");
        var data = e.dataTransfer.getData("text");
        console.log(data);
        //e.target.appendChild(document.getElementById(data));
    }

    const allowfunction=(e)=>{
        e.preventDefault();
        console.log("PERMITIR");
    }

    const dragfunction=(e)=>{        
        previo=e.target.id;
        e.dataTransfer.setData("text", e.target.id);                
    }

    const dragstopfunction=(e)=>{       
       e.stopPropagation();       
    }*/

    const moveCard = (dragIndex, hoverIndex) => {
        const newArray=[...pitems];
        const dragCard = newArray[dragIndex];
        const newOrder= update(newArray, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          });

        setPitems(newOrder);        
        //console.log(newOrder);
        
      } 


      const anadirHistoria=()=>{
          setHistoria(true);
      }

      const editarHistoriaVital=()=>{        
        props.setItos(false);
        props.setDataHistoria({editar:true,...historiaVital});
      }
    

    return (
        <Container fluid>
            <Row>
                <Col>
                <h4>Historial vital</h4>
                </Col>

            </Row>
            <Row>
                <Col>
                <Card>
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col>
                                <p><strong>Titulo</strong></p>
                                <p>{historiaVital.titulo}</p>
                                </Col>
                                <Col>
                                <p><strong>Temas a tratar</strong></p>
                                <p>{historiaVital.temas_a_tratar}</p>
                                </Col>
                                <Col>
                                <p><strong>Temas a evitar</strong></p>
                                <p>{historiaVital.temas_a_evitar}</p>
                                </Col>
                                <Col>
                                <p><strong>Otros comentarios</strong></p>
                                <p>{historiaVital.otros_comentarios}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <a href="#" onClick={editarHistoriaVital} className="link">Editar datos</a>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <VerticalTimeline layout={"1-column"}  >
                        <div id="container" >
                        {
                            pitems.map((item, index) => { 

                              return(
                              <div className="">
                                  <Item key={index} index={index}
                                    id={"d_"+index}
                                    text={item.titulo}
                                    item={item}
                                    moveCard={moveCard}
                                    index={index}
                                    pitems={pitems}
                                    setPitems={setPitems}                                    
                                    setHistoria={setHistoria}
                                    listHistoriaVital={listHistoriaVital}
                                  />
                                </div>
                                )
                            }
                            )
                        }
                       
                     </div>            
                     {
                        addhistoria && (
                            <VerticalTimelineElement                                                                                
                                    className={"item_vertical"}
                                    iconStyle={{ background: '#adb8f3', color: '#adb8f3' }}
                                    // icon={item.terminado && <Icono/>}                                        
                                    position={"right"}                                         
                            >
                                
                            <NewItem setHistoria={setHistoria} listHistoriaVital={listHistoriaVital} setPitems={setPitems} onClick={()=>setHistoria(false)}/>

                            </VerticalTimelineElement>

                        ) 
                     }         

                        <div onClick={anadirHistoria} style={{cursor:"pointer",backgroundColor:"#e6e9fb",height:70,display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <img src={Plus} style={{padding:10}}/>
                            <span style={{fontWeight:"bold",fontSize:20}}>Añadir otro</span>
                        </div>
                    </VerticalTimeline>                    
                </Col>
            </Row>
            {/* <Row>
                <Col>
                    <Necesitas />
                </Col>
            </Row> */}
        </Container>
    );

}