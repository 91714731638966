import React from 'react';
import {Auth} from "aws-amplify";
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router';
export default function Logout(props){



    const history = useHistory();

    const desconectar=()=>{
    
        Auth.signOut().then(data=>{
        localStorage.clear();
        props.userHasAuthenticated(false);
        history.push('/');
        }).catch(error=>{
    
        });
        
      };
    React.useEffect( () => {
        desconectar();
    }, [] )


    

    


      return (
          'Cerrando sesión'
      );


}