import React from "react";
import "./anadirHitos.css";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import {Container,Row,Col} from "react-bootstrap";
import Necesitas from "../../components/Necesitas/Necesitas";

export default function anadirHitos(props) {

    const atras=()=>{
        props.history.goBack();
    };

    const guardar=()=>{
        props.history.push("hitos");
    }
    
  return (
    <Container fluid>
     <Row>
        <Col  className="mt-5 d-flex justify-content-center"><span className="h1">Completa la historia vital</span></Col>
      </Row>
      <Row>
         <Col  className="mb-5 mt-3 d-flex justify-content-center" ><span className="h5">Añade tantos hitos como quieras</span></Col>
      </Row>   

      <Row>
          <Col>
            <VerticalTimeline layout={"1-column"}>        

                <VerticalTimelineElement                                                                     
                iconStyle={{ background: '#adb8f3', color: '#adb8f3' }}    
                position={"right"}                                                                      
                >

                <Row style={{paddingBottom:15}}>
                <Col className="d-flex justify-content-start">
            <div className="anadirHitos">
            <Row style={{marginBottom:30}}><Col xs={11} md={11}><span className="h5">Añadir punto en la historia</span></Col><Col xs={1} md={1}><span className="closeX" onClick={atras}>X</span></Col></Row>
            <Row className="fila">
                <Col xs={12} md={3}>
                    Especifica el año o la época
                </Col>
                <Col xs={12} md={9}>
                    <textarea placeholder="Año" className="form-control">                
                    </textarea>
                </Col>          
            </Row>
            <Row className="fila">
                <Col xs={12} md={3}>
                    Explica que cosas ocurrieron, ¿por qué recuerdas esta fecha?, ¿qué fue importante?
                </Col>
                <Col xs={12} md={9}>
                    <textarea placeholder="Historia" className="form-control">                
                    </textarea>
                </Col>
            </Row>
            <Row className="fila">
                <Col xs={12} md={3}>
                    Si quieres puedes añadir vídeos o fotografías del momento. Pulsa el botón añadir
                </Col>
                <Col xs={12} md={9} >
                    <div style={{backgroundColor:"#f6f7fd",height:"4rem",display:"flex",alignItems:"center"}}>
                        <button className="botonBorde" >Añadir fotografías o vídeos</button>
                    </div>
                </Col>
            </Row>
            <Row className="fila">
                <Col xs={12} md={3}></Col>
                    <Col xs={12} md={9}>
                        <button  className="btn simpleBoton" onClick={guardar}  style={{marginTop:10}}  type="button">
                            Guardar
                        </button>
                    </Col>
            </Row>
            </div>

      </Col>
                </Row>      

                </VerticalTimelineElement>                

        </VerticalTimeline>
    </Col>
    </Row>
    {/* <Row>
        <Col>
        <Necesitas />
        </Col>
    </Row> */}
    </Container>
  );

}