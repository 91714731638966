import React from "react";
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Pedido from '../Pedido';
export default function ConfirmarUsuario(props) {

    const {email,autoLogin} = props; 
    const [code,setCode] = React.useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    
    const confirmUser = async () => {
        setIsSubmitting(true); 
        try {
        const confirm =  await Auth.confirmSignUp(email, code);
        
        if(confirm == 'SUCCESS'){
            autoLogin();
        }
        if(confirm.code == 'ExpiredCodeException'){

        }
        console.log(confirm);
        }catch (err){
        console.log(err);
        setIsSubmitting(false);
        }
        
      }

      

    return (
        <div>
            
                <Container className="p-5">
                <Row className="mt-5">
                    <Col>
                        <h3 className="text-center customTitle">Confirma tu correo electrónico</h3>
                        <p className="text-center" >Recibirás un email con un código de 6 dígitos</p>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col lg={{span:6, offset:3}}>
                        <Card>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col>
                                        <Form.Group>
                                            <input type="number"
                                            name="code"
                                            onChange={ (e) => {setCode(e.target.value) } }
                                            className="form-control customInput"
                                            placeholder="Ingresa el código de verificación"/>
                                        </Form.Group>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                    <Col>
                                        <button onClick={confirmUser} disabled={isSubmitting} className="btn md-button-solid">Validar Correo</button>
                                    </Col>
                                    </Row>
                                

                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
            
            
        </div>
    );

}