import React from "react";
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import { useHistory } from "react-router-dom";
import axios from "axios";
import global from '../../../config';
import './dashboard.css';
export default function AdminDashboard(props) {



    const [pedidos,setPedidos]=React.useState([]);


    React.useEffect(() => { 
        props.setActiva("/admin/dashboard");
        getPedidos();
      }, []); 


    async function getPedidos() {        
        await axios.get(global.url+'configuraciones/listPedidos',{headers: { 'token': localStorage.getItem('token') } })
          .then(response => {                   
            setPedidos(response.data.data);            
          })
          .catch(error => {
            console.log(error);
          });
      }
  
    const history = useHistory();
    return (

        <>
            <Container>
                <Row className="mt-5">
                    <Col lg={5}>
                        <Card>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col>
                                            <h2 className="customTitle">Dashboard</h2>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <FormControl as="select">
                                                    <option>Últimos 15 días</option>
                                                </FormControl>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h3><strong>{pedidos.length}</strong></h3>
                                            <p>nuevos pedidos</p>
                                        </Col>
                                        <Col>
                                            <h3>0</h3>
                                            <p>pedidos activos</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h3><strong>0</strong></h3>
                                            <p>visualizaciones</p>
                                        </Col>
                                        <Col>
                                            <h3>0</h3>
                                            <p>veces compartido</p>
                                        </Col>
                                    </Row>

                                </Container>


                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={7}>
                        <Card>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col>
                                            <h2 className="customTitle">Nuevos Pedidos</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div>
                                                <Table striped bordered hover responsive="lg">
                                                    <tbody>
                                                        {pedidos !=undefined ?
                                                        <>
                                                        {
                                                            pedidos.map(item=>{
                                                                return(<tr>
                                                                    <td>{item.fecha}</td>
                                                                    <td>{item.id}</td>
                                                                    <td>{item.user.name} {item.user.family_name}</td>
                                                                    <td>Pagado</td>
                                                                    <td><span className="link-outlined" style={{cursor:"pointer"}} href="#" onClick={()=> history.push("/admin/detalles",{item})}>Ver detalles</span></td>
                                                                </tr>)
                                                            })
                                                        }
                                                        </>
                                                        : null
                                                        }
                                                        {/* <tr>
                                                            <td>2 abr 2020</td>
                                                            <td>2984109384</td>
                                                            <td>Cvita Doleschall</td>
                                                            <td>Pagado</td>
                                                            <td><a className="link-outlined" href="javascript:void(0)" onClick={()=> history.push("/admin/detalles")}>Ver detalles</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2 abr 2020</td>
                                                            <td>2984109384</td>
                                                            <td>Cvita Doleschall</td>
                                                            <td>Pagado</td>
                                                            <td><butaton className="link-outlined" href="javascript:void(0)" onClick={()=> history.push("/admin/detalles")}>Ver detalles</butaton></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2 abr 2020</td>
                                                            <td>2984109384</td>
                                                            <td>Cvita Doleschall</td>
                                                            <td>Pagado</td>
                                                            <td><a className="link-outlined"  href="javascript:void(0)" onClick={()=> history.push("/admin/detalles")}>Ver detalles</a></td>
                                                        </tr> */}

                                                    </tbody>
                                                </Table>

                                            </div>
                                        </Col>
                                    </Row>
                                </Container>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>

    );


}