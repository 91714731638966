import types from '../types/types';
const initialState = {
    isAuthenticated: false,
    token: null
}

 const loginReducer = (state = initialState, action) => {
    
    switch(action.type){
    
        case types.login: {
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token
            }
        }
        default:
            return state;
    }
  }
export default loginReducer;