import React from "react";
import { Switch } from "react-router-dom";
import NotFound from "./screens/client/NotFound";
import login from "./screens/client/login/Login";
import TuAlmadentro from "./screens/client/tualmadentro/tualmadentro";
import Completa from "./screens/client/tualmadentro/Completa/Completa";
import Hitos from "./screens/client/tualmadentro/hitos/hitos";
import Configuracion from "./screens/client/configuracion/Configuracion";
import FacturacionPagos from "./screens/client/facturacion/FacturacionPagos";
import AuthenticatedRoute from "./screens/client/components/AuthenticatedRoute";
import ProtectedRoute from "./screens/client/components/ProtectedRoute";
import UnauthenticatedRoute from "./screens/client/components/UnauthenticatedRoute";
import anadirHitos from "./screens/client/tualmadentro/anadirHitos/anadirHitos";
import uiKit from './screens/UI-KIT/ui-kit';
import NuevoDestinatario from './screens/client/configuracion/nuevo-destinatario/nuevo-destinatario';
import Pedido from './screens/client/pedido/Pedido';
import Pago from './screens/client/pedido/Pago/Pago';
import Confirmacion from './screens/client/pedido/Confirmacion/Confirmacion';
import LoginAdmin from './screens/admin/login/login';
import AdminDashboard from './screens/admin/dashboard/dashboard';
import FechasReservadas from './screens/admin/fechas/FechasReservadas';
import ClientesDashboard from './screens/admin/clientes/clientes';
import DetallesDashboard from './screens/admin/clientes/detalles/detalles';
import { Route, Redirect } from "react-router-dom";
import AppliedRoute from './screens/client/components/AppliedRoute';
import Logout from './screens/client/logout/logout';
export default function Routes({ appProps }) {
  
  
  return (     
    <Switch>
      <UnauthenticatedRoute path="/"  exact appProps={appProps} component={login} /> 
      <UnauthenticatedRoute path="/login"  exact appProps={appProps} component={login} />
      <AppliedRoute path="/reserva" exact  appProps={appProps} component={Pedido} />
      <AuthenticatedRoute path="/tualmadentro" rol="client" appProps={appProps} exact component={TuAlmadentro} />
      <AppliedRoute path="/logout"  appProps={appProps} exact component={Logout} />
      <AuthenticatedRoute path="/completahistoria" rol="client" appProps={appProps} exact component={Completa} />
      <AuthenticatedRoute path="/hitos" rol="client" appProps={appProps} exact component={Hitos} />
      <AuthenticatedRoute path="/anadirhitos" rol="client" appProps={appProps} exact component={anadirHitos} />
      <AuthenticatedRoute path="/configuracion" rol="client" appProps={appProps} exact component={Configuracion} />
      <AuthenticatedRoute path="/facturacionpagos" rol="client" appProps={appProps} exact component={FacturacionPagos} />
      <AuthenticatedRoute path="/ui-kit" rol="client" appProps={appProps} exact component={uiKit} />
      <AuthenticatedRoute path="/pedido" rol="client" appProps={appProps} exact component={Pedido} />
      <AuthenticatedRoute path="/pago" rol="client" appProps={appProps} exact component={Pago} />
      <AuthenticatedRoute path="/confirmacion" rol="client" appProps={appProps} exact component={Confirmacion} />
      <AuthenticatedRoute path="/admin/dashboard" rol="admin" appProps={appProps} exact component={AdminDashboard} /> 
      <AuthenticatedRoute path="/admin/clientes" rol="admin" appProps={appProps} exact component={ClientesDashboard} />
      <AuthenticatedRoute path="/admin/detalles" rol="admin" appProps={appProps} exact component={DetallesDashboard} />
      <AuthenticatedRoute path="/admin/fechas" rol="admin" appProps={appProps} exact component={FechasReservadas} />
      
      
      
      
      <AppliedRoute component={NotFound} />
    </Switch>
  );
}