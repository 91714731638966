import React from "react";

import './Login.css';
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import Necesitas from "../components/Necesitas/Necesitas";
import { Auth } from "aws-amplify";
import RecuperarClave from "./RecuperarClave";
import {connect, useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import './../../UI-KIT/ui-kit.css';
import loginAction from '../../../redux/actions/loginAction';
function Login(props) {    
  const history = useHistory();
  const dispatch = useDispatch();
  //console.log(props);
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState(); 
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState('');
  const [showRecuperarClave, setShowRecuperarClave] = React.useState(false);
  const [mensajeNecesitas, setMensajeNecesitas]  = React.useState('¿Dudas?');
  React.useEffect(()=>{
    /*Auth.currentAuthenticatedUser()
    .then(user => Auth.userAttributes(user))
    .then(attributes => console.log(attributes))
    .catch(err => console.log(err));*/

  },[]); 

 

   const ingresar = async (event)  => {  
    setIsSubmitting(true);
  event.preventDefault();
  try {        
      //await Auth.confirmSignUp(email, '615827');
    const newUser = await Auth.signIn(email,password);
    console.log(newUser);
    let role = newUser.attributes['custom:rol'];
    console.log("ROL:"+role);
    props.setAttributes(newUser.attributes); 
    
    let token = newUser.signInUserSession.idToken.jwtToken;
    localStorage.setItem("token",token);
    localStorage.setItem("token_1",newUser.signInUserSession.accessToken.jwtToken);
    setIsSubmitting(false);
    props.userHasAuthenticated(true);
    dispatch(loginAction(token));
    
    
    if(role === 'client'){      
      props.history.push("/tualmadentro");      
    }else if(role === 'admin'){
      props.setRole(role); 
      props.history.push("/admin/dashboard");
    }
  }catch(err){
    
    console.log(err);
    setIsSubmitting(false);
    setError('Usuario o contraseña inválidos');
    console.log(err.message);
    //throw new Error(err.message);
  }
 }

const reserva = () => {
  history.push('/reserva');
}



  return (
      <>
      {!showRecuperarClave
      ?
      <>
        <Container fluid  className="contenido">
          <Row className="seccionLogin">
            <h2 style={{marginBottom:20,marginTop:4}}>Accede a tu cuenta</h2>
            <div className="login">
              <div className="login-container">
                <h4 style={{marginTop:0,marginBottom:25,textAlign:"center"}}>Introduce tus datos</h4>

                <form
                    style={
                      {display:"flex",
                        flexDirection:"column",
                        width:"100%"
                      }}
                    onSubmit={ingresar}>
                  <input type="email" className="input" onChange={(e) => setEmail(e.target.value)}  placeholder="Email" required/>
                  <input type="password" className="input" onChange={(e) => setPassword(e.target.value)} placeholder="Contraseña" required/>
                  <Button size="lg" style={{width:"100%",backgroundColor:"#f5b96b",borderColor:"#f5b96b",borderRadius:0}} disabled={isSubmitting}  type="submit">
                    Acceder
                  </Button>
                </form>


                <a className="olvido" href="#" onClick={(e)=> { setShowRecuperarClave(true) }}>He olvidado mi contraseña</a>
                <a href="#" onClick={reserva} className="link-solid">Registrarme</a>
              </div>
              <section>
                <><br/>{error && <div className="alert alert-danger">{error}</div>}</>
              </section>
            </div>
          </Row>


        </Container>

        <Necesitas mensajeNecesitas={mensajeNecesitas} />

        </>
      : <RecuperarClave/>
      }

      </>

  );
}


const mapStateToProps=state=>({token:state.token,attributes:state.attributes});
const mapDispatchToProps=dispatch=>({
  ingresar(data){
      dispatch({
          type:"ingresar",
          data
      })
  }  
});

export default Login;//connect(mapStateToProps,mapDispatchToProps)(Login);