import React from "react";
import {Row, Container, Col} from "react-bootstrap";
import  "./Necesitas.css";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

export default function Necesitas(props) {

  let estilo = props.estiloFuente;
  let mensaje = props.mensajeNecesitas;
  let tamano = props.tamanoFuente;

  if (!props.estiloFuente) {
    estilo="rubik-regular";
  }

  if(!props.tamanoFuente) {
    tamano="18px";
  }

  return (
      <>
        <Container fluid>
          <div className="mt-5">
            <Row className="seccionInformacion">
              <h4 style={{margin:0, fontFamily:`${estilo}`, fontSize:`${tamano}` }}><b>{mensaje}</b></h4>
              <p style={{textAlign:"center",fontSize:16,margin:0}}>
              Llámanos al <a href="tel:+34644095959">+34644095959</a> o escríbenos a <a href="mailto:hola@almadentro.com">hola@almadentro.com</a> 
              </p>
              <Row style={{fontSize:17}}>
                <div>
                Lunes a viernes, de 9:00 a 18:00h
                </div>
              </Row>
            </Row>
          </div>
        </Container>
      </>

  );

}