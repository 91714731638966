import React, { useImperativeHandle, useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import Chispas from "../../../../assets/img/chispas.png";
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import "./hitos.css";
import { Container, Row, Col } from "react-bootstrap";
import NewItem from "./newItem";
import axios from 'axios';
import global from '../../../../config';

const ItemTypes={CARD:"TEXTO"}
const Icono=()=>(
    <img src={Chispas} className="imagen_icono" />
);



const Item = React.forwardRef(
  ({ listHistoriaVital,text, isDragging, connectDragSource, connectDropTarget,item,index,pitems,setPitems,fotos,videos,setHistoria}, ref) => {
    const elementRef = useRef(null)
    connectDragSource(elementRef)
    connectDropTarget(elementRef)
    const opacity = isDragging ? 0 : 1
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }))


    const editar = async (index) => {

        pitems[index].editar = true;
        const multimedia=pitems[index].multimedia;
        // for(let i=0;i<multimedia.length;i++){
        //   let key=multimedia[i].srcVideo;  
        //   let data= await axios.post(global.url+"multimedia/getFileBase64",{key:key});               
        //   const cache = await caches.open('files');                              
        //   //console.log(data.data);
        //   //const b64String = data.data.split(',')[1];          
        //   var byteString = atob(data.data.data);
        //   var arrayBuffer = new ArrayBuffer(byteString.length);
        //   var intArray = new Uint8Array(arrayBuffer);
        //   for (let j = 0; j < byteString.length; j++) {
        //       intArray[j] = byteString.charCodeAt(j);
        //   }
        //   var imageBlob = new Blob([intArray], {type: 'image/png'});
        //   const res = new Response(imageBlob, {
        //     status: 200,
        //     statusText: "OK",
        //     name:key,
        //     headers: {
        //       "Content-Type": "image/png",
        //       "name":key,
        //       "Content-Length": imageBlob.size
        //     },
        //   });

        //   cache.put(key,res);
        //   /*if(sessionStorage.getItem(key)===null){
        //     let data= await axios.post(global.url+"multimedia/getFileBase64",{key:key});               
        //     sessionStorage.setItem(key,"");            
        //     cache.put(key,data.data.data);
        //   }*/
        // }

        setPitems([...pitems]);
    }

    const cerrar = () => {

        pitems[index].editar = false;
        setPitems([...pitems]);
    }


    return (
      <div ref={elementRef} style={{ opacity }}>
        <VerticalTimelineElement                                                                                
            className={item.terminado ? "item_vertical":"noterminado"}
            iconStyle={{ background: '#adb8f3', color: '#adb8f3' }}
            // icon={item.terminado && <Icono/>}                                        
            position={"right"}                                         
       >

            <div   style={{display:"flex",flexDirection:"row"}} className="item">
                    {!item.editar ?
                    
                    <Row style={{ paddingBottom: 15, padding:15 }}>
                        <Col md={3}>
                            <span className="h4" style={{ fontWeight: "bold" }}>{item.fecha}</span>
                        </Col>
                        <Col md={8}>
                            <p>
                                {item.descripcion}
                            </p>

                            <div style={{display:"flex"}}>
                            { item.fotos>0 &&
                              <span style={{ color: "#ffb65b", fontWeight: "bold", fontSize: ".8rem",paddingRight:10 }}>{item.fotos} fotografías</span>
                            }

                            { item.videos>0 &&
                              <span style={{ color: "#ffb65b", fontWeight: "bold", fontSize: ".8rem" }}>{item.videos} vídeos</span>
                            }
                            </div>

                        </Col>
                        <Col md={1} style={{ display: "flex", flexDirection: "column" }}>
                            <p style={{ color: "#adb8f3", fontWeight: "bold", fontSize: ".8rem", textDecoration: "underline", cursor: "pointer" }} onClick={() => editar(index)}>Editar</p>
                        </Col>                        
                    </Row>                    
                    : <NewItem item={item} listHistoriaVital={listHistoriaVital} setHistoria={setHistoria} setPitems={setPitems} onClick={cerrar}/>
                }

            <div className="item_arrastrar">
                <div style={{flex:3,display:"flex",alignItems:"center"}}>
                    <img draggable="false" src={require("../../../../assets/img/move.png")} style={{width:"38px"}}/>
                </div>
                <div>
                    <span style={{fontSize:"13px",color:"rgb(173, 184, 243)"}}>Mover</span>
                </div>
            </div>  

            </div>       

       </VerticalTimelineElement>
      </div>
    )
  },
)
export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null
      }
      // node = HTML Div element from imperative API
      const node = component.getNode()
      if (!node) {
        return null
      }
      const dragIndex = monitor.getItem().index
      const hoverIndex = props.index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action      
      props.moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex

      // const indice=props.index;      
      // const item_data=props.item;
      // let pitems_data=[...props.pitems];     
      // console.log(pitems_data); 
      /*pitems_data.splice(indice,0,item_data);  
      console.log(pitems_data);
      console.log(props);*/
    },drop(item,monitor) {
      /*const indice=item.index;
      const item_source=item.item;*/  
      const newArray=Object.assign([],item.pitems);      

      axios({
        method: "put",
        headers: {                
            'token': localStorage.getItem('token')
        },
        data: {matriz:newArray},
        url: global.url+'historiavital/updateOrden'
    }).then(data=>{
       console.log(data);
    }).catch(error=>{
      console.log(error);
    })

    }
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(Item),
)
