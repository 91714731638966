import types from '../types/types';
const initialState = {
    titulo: '',
}

 const tituloReducer = (state = initialState, action) => {
    
    switch(action.type){
    
        case types.setTitulo: {
            return {
                ...state,
                titulo: action.payload.titulo,
            }
        }
        default:
            return state;
    }
  }
export default tituloReducer;