import React from "react";
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import { useHistory } from "react-router-dom";
import axios from "axios";
import global from '../../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function FechasReservadas(props) {


    React.useEffect(() => {
        props.setActiva("/admin/fechas");
        getFechasReservadas();
    }, []);

    const [fechasReservadas, setFechasReservadas] = React.useState([]);
    const [disabled, setDisabled] = React.useState(false);
    const [fecha, setFecha] = React.useState(null);

    async function getFechasReservadas(){

        await axios({
            method: 'get',
            url: global.url + 'fechas/reservadas/get',
            headers: { token: localStorage.getItem('token') }
          })
          .then(response => {
            setFechasReservadas(response.data.data);
              //alert(response);
              
          })
          .catch(error => {
            console.log(error)
          });

    }

    const deleteFecha = async (e) => {
        setDisabled(true);
        console.log(e);
        let fechaBorrar = new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1)+'-'+new Date(e).getDate();
        let params = {
            fecha: fechaBorrar
        }
        console.log(new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1)+'-'+new Date(e).getDate() );
        //return;
        await  axios.post(global.url+'fechas/reservadas/delete', params  ,{ headers: { 'token': localStorage.getItem('token') } })
        .then(response => {
            setDisabled(false);
            toast.success('La fecha seleccionada fue borrada exitosamente');
            console.log(response);
            getFechasReservadas();
        })
        .catch(error => {
            setDisabled(false);
            console.log(error);
        })
        
    }

    const nuevaFecha = async (e) => {
        setDisabled(true);
        console.log(fecha);
        let params = {
            fecha: fecha
        }
        
        await  axios.post(global.url+'fechas/reservadas/add', params  ,{ headers: { 'token': localStorage.getItem('token') } })
        .then(response => {
            setDisabled(false);
            toast.success('La fecha seleccionada fue reservada exitosamente');
            console.log(response);
            getFechasReservadas();
        })
        .catch(error => {
            setDisabled(false);
            console.log(error);
        })
        
    }

    return(<>
    <Container>
    <ToastContainer />
    <Row className="mt-5">
        <div className="col-lg-4">
            <label>Seleccione una fecha para reservar</label>
            <input type="date" onChange={(e)=>{e.preventDefault(); setFecha(e.target.value)}} className="form-control" />
        </div>
        <div className="col-lg-4">
            <button type="button" onClick={(e)=> {nuevaFecha();}} className="btn btn-success">Guardar </button>
        </div>
    </Row>
    <Row className="mt-5">
                    <Col>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>FECHA</th>
                                    <th>OPCIONES</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                { fechasReservadas.map((item,index)=>{
                                  return(
                                  <tr key={index}>
                                    <td>{new Date(item).toDateString()}</td>
                                    <td><button tyepe="button" disabled={disabled} onClick={(e)=>{e.preventDefault(); deleteFecha(item)}} className="btn btn-danger">Eliminar</button></td>
                                    
                                  </tr>
                                  )
                                })                                
                                }
                            </tbody>

                        </Table>
                    </Col>
                </Row>
    </Container>
    </>);

}