import React from "react";
import "./Pedido.css";
import { Row, Col, Container, Card, Form, InputGroup , FormControl, Button } from "react-bootstrap";
import Checkbox from '@material-ui/core/Checkbox';
import video from '../../../assets/img/video.svg';
import foto from '../../../assets/img/foto.svg';
import movil from '../../../assets/img/movil.svg';
import envio from '../../../assets/img/envio.svg';
import cava from '../../../assets/img/cava.svg';
import Pago from './Pago/Pago';
import Confirmacion from './Confirmacion/Confirmacion';
import { Formik } from 'formik';
import { Auth } from "aws-amplify";
import global from '../../../config';
import axios from 'axios';
import ConfirmarUsuario from './ConfirmarUsuario/usuario';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Necesitas from "../components/Necesitas/Necesitas";
import NumericInput from 'react-numeric-input';
export default function Pedido(props) {

  const { isAuthenticated, email, password } = props;
  let mensajeNecesitas = "¿Necesitas ayuda?";
  let tamanoFuente ="3rem";
  let estiloFuente = "libre-baskerville-italic";
  /*
    METODOS PARA CONSUMIR EL API
  */

  const [dataExtras, setDataExtras] = React.useState([]);
  const [valorAlmadentro, setValorAlmadentro] = React.useState(1000);
  const [valorExtras, setValorExtras] = React.useState(0);
  const [valorPedido, setValorPedido] = React.useState(0);
  const [packExtraCheck, setPackExtraCheck] = React.useState(false);
  const [cantidadPacksExtras, setCantidadPacksExtras] = React.useState(1);
  const [checked, setChecked] = React.useState(true);
  const [pedido, setPedido] = React.useState(true);
  const [pago, setPago] = React.useState(false);
  const [confirmacion, setConfirmacion] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [registro, setRegistro] = React.useState(true);
  const [dataUser, setDataUser] = React.useState([]);
  const [confirmUser, setConfirmUser] = React.useState(false);
  const [registeredEmail, setRegisteredEmail] = React.useState('');
  const [registeredPassword, setRegisteredPassword] = React.useState('');
  const [isAutoLogin, setIsAutoLogin] = React.useState(false);


  React.useEffect(() => {
    if (!isAuthenticated) {
      getExtras();
      setDataUser(props.attributes);
    }


  }, []);

  async function getExtras() {
    await axios.get(global.url+'configuraciones/listExtras', { headers: { 'token': localStorage.getItem('token') } })
      .then(response => {
        setDataExtras(response.data.data)
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };




const handleChangeCantPackExtraRestar = (event, precio) => {
  let valor = valorExtras - precio; 
  if(packExtraCheck){
    if(cantidadPacksExtras > 1){
      setCantidadPacksExtras(valor => valor - 1);  
      setValorExtras(valor);
    }
    
  }else{
    toast.error('Para agregar cantidades del pack extra, debe estar seleccionado');
  }
  
}

const handleChangeCantPackExtraSumar = (event, precio) => {
  let valor = valorExtras + precio; 
  console.log(packExtraCheck);
  if(packExtraCheck){
    setCantidadPacksExtras(valor => valor + 1);  
    setValorExtras(valor);
  }else{
    toast.error('Para agregar cantidades del pack extra, debe estar seleccionado');
  }
  
}

  const handleCheckExtras = (event, data, index, cantidad,packextra) => {
    var restarValor;
    
    console.log(data);
    

    if (data > 0) {
      let check = event.target.checked;
      let sumarValor = valorExtras + data;
      if(packextra){
        setPackExtraCheck(true);
        if (!check) {
          let restapacks = cantidadPacksExtras * data;
          restarValor = valorExtras - restapacks;
          console.log(restapacks,'resta packs');
          console.log(restarValor,'resta valor');
        }else{
          restarValor = valorExtras - data;
        }
      }else{
        restarValor = valorExtras - data;
      }
      
      if (check) {
        setValorExtras(sumarValor);
        dataExtras[index].incluir = 'SI';
        setDataExtras([...dataExtras]);
      } else {
        setValorExtras(restarValor);
        dataExtras[index].incluir = null;
        setDataExtras([...dataExtras]);
        setPackExtraCheck(false);
        setCantidadPacksExtras(1);
      }
     
    }


  }


  const handlePago = () => {
    setPedido(false);
    setPago(true);
    if(!confirmUser){

    }
  }

  const handleEfectuarPago = () => {
    setPedido(false);
    setPago(false);
    setConfirmacion(true);
  }

  const handleModificarPedido = () => {
    setPago(false);
    setPedido(true);
    setRegistro(false);

  }

  const register = async (datos) => {
    setIsSubmitting(true);
    try {
      datos.username = datos.email;

      let params = {
          'username': datos.username,
          'password': datos.password,
          "name": datos.name,
          "email": datos.email,
          "attributes": {
            "address": datos.address,
            "name": datos.name,
            "family_name": datos.family_name,
            "phone_number": datos.phone_number,
            "custom:ciudad": datos.ciudad,
            "custom:provincia": datos.provincia,
            "custom:codigo_postal": datos.codigo_postal,
            "custom:rol": 'client'
          }
      }
      if(datos.dni){
        params.attributes['custom:dni'] = datos.dni
      }
      console.log('[Mostrando params]',params);
      const newUser = await Auth.signUp(params);
      setIsSubmitting(false);
      console.log(newUser);
      setRegisteredEmail(datos.email);
      setRegisteredPassword(datos.password);
      setPedido(false);
      setConfirmUser(true);
      //alert(newUser);
      //return;
      //handlePago();
    } catch (err) {
      console.log(err.message);
      if(err.message == 'An account with the given email already exists.'){
        toast.error('La dirección de correo electrónico ya se encuentra registrada');
        setIsSubmitting(false);
      }
      setIsSubmitting(false);
      //throw new Error(err.message);

    }


  }

  const autoLogin = async () => {
    try{
    const newUser = await Auth.signIn(registeredEmail,registeredPassword);
    let token = newUser.signInUserSession.idToken.jwtToken;
    localStorage.setItem("token",token);
    localStorage.setItem("token_1",newUser.signInUserSession.accessToken.jwtToken);
    props.setAttributes(newUser.attributes);
    props.userHasAuthenticated(true);
    setDataUser(newUser.attributes);

    setPago(false);
    setPedido(true);
    setRegistro(false);
    setConfirmUser(false);

    }catch(err){
      console.log(err);

    }
  }




  return (
    <>
      <ToastContainer />
      {pedido ?
        <>
          <Container className="p-5">
            <Row className="mt-5">
              <Col>
                <h3 className="text-center customTitle">Reserva tu Almadentro</h3>
              </Col>
            </Row>



            <Formik
              initialValues={{ name: '' }}
                validate={values => {
                  const errors = {};

                  if (registro) {

                    if (!values.email) {
                      errors.email = 'Requerido';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                      errors.email = 'Email no válido';
                    }

                    if(!values.name){
                      errors.name='Requerido';
                    }

                    if(!values.family_name){
                      errors.family_name='Requerido';
                    }

                    if(!values.address){
                      errors.address='Requerido';
                    }

                    if(!values.provincia)
                    {
                       errors.provincia='Requerido';
                    }

                    if(!values.ciudad)
                    {
                       errors.ciudad='Requerido';
                    }


                    if(!values.codigo_postal){
                      errors.codigo_postal='Requerido';
                    }

                    if(isNaN(values.codigo_postal)){
                      errors.codigo_postal='No es código valido';
                    }

                    if(!values.phone_number){
                      errors.phone_number='Requerido';
                    }else if(!/^\+[1-9]{1}[0-9]{1,3}[0-9]{3,14}$/.test(values.phone_number))
                    {
                      errors.phone_number='Introduzca el teléfono incluyendo el código del país (ej. +34), sin espacios o guiones';
                    }

                    if(isNaN(values.phone_number)){
                      errors.phone_number='No es un número valido';
                    }

                    if(!values.password){
                      errors.password='Requerido';
                    }else if(
                      //!/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(values.password)
                      !/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*).*$/.test(values.password)
                      
                    ){
                      errors.password='La contraseña debe tener entre 8 y 15 caracteres, y al menos una letra minúscula, una mayúscula y un número';
                    }



                  }



                  return errors;
                }
              }
              onSubmit={(values, { setIsSubmitting }) => {
                const datos = JSON.stringify(values, null, 2);
                console.log(datos);
                if (registro) {
                  register(values);
                } else {
                  handlePago();
                }





              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                /* and other goodies */
              }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="mt-5">
                      <Col lg={7}>
                        <Card>
                          <Card.Body>

                            {registro &&
                              <>
                                <Form.Row>
                                  <Form.Group>
                                    <Col>
                                      <h5>Datos de facturación</h5>
                                    </Col>
                                  </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                  <Col lg={6}>
                                    <Form.Group>
                                      <input type="text" name="name" onChange={handleChange}
                                        onBlur={handleBlur} value={values.name || ''} className="form-control customInput" placeholder="Nombre"></input>
                                      {errors.name && touched.name && errors.name}
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group>
                                      <input type="text" name="family_name" onChange={handleChange}
                                        onBlur={handleBlur} value={values.family_name || ''} className="form-control customInput" placeholder="Apellidos"></input>
                                      {errors.family_name && touched.family_name && errors.family_name}
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Form.Row>
                                  <Col>
                                    <Form.Group>
                                      <input type="text" name="address" onChange={handleChange}
                                        onBlur={handleBlur} value={values.address || ''} className="form-control customInput" placeholder="Dirección"></input>
                                      {errors.address && touched.address && errors.address}
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Form.Row>
                                  <Col lg={6}>
                                    <Form.Group>
                                      <input type="text" name="provincia" onChange={handleChange}
                                        onBlur={handleBlur} value={values.provincia || ''} className="form-control customInput" placeholder="Provincia"></input>
                                      {errors.provincia && touched.provincia && errors.provincia}
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group>
                                      <input type="text" name="ciudad" onChange={handleChange}
                                        onBlur={handleBlur} value={values.ciudad || ''} className="form-control customInput" placeholder="Ciudad"></input>
                                      {errors.ciudad && touched.ciudad && errors.ciudad}
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Form.Row>
                                  <Col lg={6}>
                                    <Form.Group>
                                      <input type="text" name="codigo_postal" onChange={handleChange}
                                        onBlur={handleBlur} value={values.codigo_postal || ''} className="form-control customInput" placeholder="Código Postal"></input>
                                      {errors.codigo_postal && touched.codigo_postal && errors.codigo_postal}
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group>
                                      <input type="text" name="dni" onChange={handleChange}
                                        onBlur={handleBlur} value={values.dni || ''} className="form-control customInput" placeholder="DNI"></input>
                                      {errors.dni && touched.dni && errors.dni}
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <Form.Row>
                                  <Col lg={6}>
                                    <Form.Group>
                                      <input type="text" name="phone_number" onChange={handleChange}
                                        onBlur={handleBlur} value={values.phone_number || ''} className="form-control customInput" placeholder="Teléfono"></input>
                                      {errors.phone_number && touched.phone_number && errors.phone_number}
                                    </Form.Group>
                                  </Col>
                                </Form.Row>
                                <hr />
                                <Form.Row>
                              <Form.Group>
                                <Col>
                                  <h5>Datos de Acceso</h5>
                                </Col>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Col lg={6}>
                                <Form.Group>
                                  <input type="text" name="email" onChange={handleChange}
                                    onBlur={handleBlur} value={values.email || ''} className="form-control customInput" placeholder="Email"></input>
                                  {errors.email && touched.email && errors.email}
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group>
                                  <input type="password" name="password" onChange={handleChange}
                                    onBlur={handleBlur} value={values.password || ''} className="form-control customInput" placeholder="Contraseña"></input>
                                  {errors.password && touched.password && errors.password}
                                </Form.Group>
                              </Col>
                            </Form.Row>
                              <hr />
                              </>
                            }
                            <Form.Row>
                              <Form.Group>
                                <Col>
                                  <h5>¿Quieres añadir algún extra?</h5>
                                </Col>
                              </Form.Group>
                            </Form.Row>

                            {
                              dataExtras.map((data, i) => {
                                
                                return (
                                  <div key={i}>

                                    <Form.Row>
                                      <Col lg={7}>
                                        <Form.Group>
                                        { data.descripcion==="pack extra" 
                                        ?
                                        <Checkbox
                                            defaultChecked={data.defecto == 'SI' || data.incluir == 'SI' ? true : false}
                                            onChange={(e) => { handleCheckExtras(e, data.precio, i, 1, true) }}
                                            color="default"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                        :
                                        <Checkbox
                                            defaultChecked={data.defecto == 'SI' || data.incluir == 'SI' ? true : false}
                                            onChange={(e) => { handleCheckExtras(e, data.precio, i, 1, false) }}
                                            color="default"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                        }

                                          
                                          <label>{data.descripcion}</label>

                                          
                                          
                                          {/* { data.description="pack extra" ? <p>eeeeextra</p> :<label>{data.descripcion}</label>} */}
                                        </Form.Group>
                                      </Col>
                                      <Col lg={3}>
                                      { data.descripcion==="pack extra" &&
                                      <InputGroup className="mt-2">
                                        <InputGroup.Prepend>
                                        <button type="button" onClick={(e)=> {handleChangeCantPackExtraRestar(e,data.precio)}} className="btn btn-xs btn-secondary">-</button>
                                      </InputGroup.Prepend>
                                      <FormControl readOnly={true} value={cantidadPacksExtras}
                                        
                                      />
                                      <InputGroup.Append>
                                      <button type="button" onClick={(e)=> {handleChangeCantPackExtraSumar(e,data.precio)}} className="btn btn-xs btn-secondary">+</button>
                                      </InputGroup.Append>
                                    </InputGroup>

                                      // <input type="number" onChange={(e) => { handleChangeCantPackExtra(e, data.precio) }} className="form-control" style={{marginTop:10}}/>
                                      } 
                                      </Col>
                                      <Col lg={2}>
                                        {data.precio ? <span>+{data.precio}€</span> : null}
                                      </Col>
                                    </Form.Row>
                                  </div>
                                );
                              }
                              )
                            }
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={5}>
                        <Card>
                          <Card.Body>
                            <Container>

                              <Row className="p-2">
                                <Col>
                                  <h5>¿Qué incluye mi Almadentro?</h5>
                                </Col>

                              </Row>
                              <Row>
                                <Col lg={1}>
                                  <img src={video} />
                                </Col>
                                <Col lg={11}>
                                  <p className="ml-2"><strong>4 packs con el vídeo</strong> de la entrevista en formato pendrive</p>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={1}>
                                  <img src={foto} />
                                </Col>
                                <Col lg={11}>
                                  <p className="ml-2">Imágenes del <strong>making of</strong></p>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={1}>
                                  <img src={movil} />
                                </Col>
                                <Col lg={11}>
                                  <p className="ml-2"><strong>Versión digital</strong> para ver desde el móvil o el ordenador</p>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={1}>
                                  <img src={envio} />
                                </Col>
                                <Col lg={11}>
                                  <p className="ml-2"> <strong>Envío postal</strong> de los packs</p>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={1}>
                                  <img src={cava} />
                                </Col>
                                <Col lg={11}>
                                  <p className="ml-2"> <strong>Una botella de cava</strong> en cada pack</p>
                                </Col>
                              </Row>

                              <hr />

                              <Row>
                                <Col lg={9}>
                                  <p>Tu Almadentro</p>
                                </Col>
                                <Col lg={3}>
                                  <p>1000€</p>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={9}>
                                  <p>Extras</p>
                                </Col>
                                <Col lg={3}>
                                  <p>{valorExtras}€</p>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <button type="submit" disabled={isSubmitting} className="btn md-button-solid mt-3">
                                    Continuar con el pago
                                  </button>
                                </Col>
                              </Row>

                            </Container>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Form>
                )}
            </Formik>
          </Container>
        </>
        :
        <>
        {confirmUser ?
        <>
        <ConfirmarUsuario email={registeredEmail} password={registeredPassword} autoLogin={autoLogin}/>
        </>
        :
      <Pago
          origen={'reserva'}
          efectuarpago={handleEfectuarPago}
          modificarpedido={handleModificarPedido}
          valorExtras={valorExtras}
          valorAlmadentro={valorAlmadentro}
          dataExtras={dataExtras}
          dataUser={dataUser} />
      
      }    
        </>
      }
      <Necesitas mensajeNecesitas={mensajeNecesitas} tamanoFuente={tamanoFuente} estiloFuente={estiloFuente} />
    </>

  );

}