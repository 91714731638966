import React from "react";
import './clientes.css';
import { Nav, Row, Col, Container, Button, ListGroup, Card, Form, InputGroup, FormControl } from "react-bootstrap";
import Table from 'react-bootstrap/Table'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DetallesDashboard from './detalles/detalles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Auth } from "aws-amplify";
import axios from 'axios';
import global from '../../../config';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));
export default function ClientesDashboard(props) {

    const [detalles, SetDetalles] = React.useState(false);
    const [clientes, setClientes] = React.useState([]);
    const [search,setSearch]=React.useState("");

    React.useEffect(() => {
        props.setActiva("/admin/clientes");
        getClientes();
    }, []);

    async function getClientes(search="*") {        
        await axios.get(global.url+'configuraciones/listUsers/'+search,{ headers: { 'token': localStorage.getItem('token') } })
          .then(response => {
            setClientes(response.data.data)            
          })
          .catch(error => {
            console.log(error);
          });
      }

      const buscar=(value)=>{        
        value=value.trim()===""?"*":value;
        setSearch(value);
        getClientes(value);
      }
    


    return (
        <div>
        {!detalles &&
            
            <Container>
                <Row className="mt-5">
                    <Col lg={9}>
                        <h3 className="customTitle">Clientes</h3>
                    </Col>
                    <Col lg={2}>
                        <form noValidate autoComplete="off">
                            <TextField onChange={(e)=>buscar(e.target.value)} id="standard-basic" label="Buscar"

                            />

                        </form>
                    </Col>

                </Row>
                <Row className="mt-5">
                    <Col>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>FECHA</th>
                                    <th>ID</th>
                                    <th>NOMBRE</th>
                                    <th>EMAIL</th>
                                    <th>PASO ACTUAL</th>
                                    <th>ESTADO</th>
                                </tr>
                            </thead>
                            <tbody>
                                { clientes.map((item,index)=>{
                                  return(<tr key={index}>
                                    <td>2 abr 2020</td>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td></td>
                                    <td></td>
                                  </tr>)
                                })                                
                                }
                            </tbody>

                        </Table>
                    </Col>
                </Row>
            </Container>
        
        }
        {detalles &&
        <DetallesDashboard regresar={()=> SetDetalles(false)}/>
        }
        </div>

    );

}