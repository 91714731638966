import React from 'react';
import './App.css';
import './screens/UI-KIT/ui-kit.css'
import { LinkContainer } from "react-router-bootstrap";
import { Navbar,Nav,Row,Col,Container} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faUserCircle,faPlusCircle,faMinusCircle,faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Auth} from "aws-amplify";
import logoAlmadentro from './assets/img/logo.png';
import logoFacebook from './assets/img/fb.png';
import logoYoutube from './assets/img/youtube.png';
import logoInstagram from './assets/img/insta.png';
import iconoTelefono from "./assets/img/telefono.svg";
//import {connect} from "react-redux";

function App(props) {

  //console.log(props);

  function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = decodeURIComponent(atob(base64Url).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(base64);
 };

 
  

  library.add(fab, faUserCircle,faPlusCircle,faMinusCircle,faTimesCircle);
  

  const [isAuthenticated, userHasAuthenticated] = React.useState(false);
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);
  const [activa, setActiva] = React.useState("");
  const [attributes,setAttributes]=React.useState("");
  const [role,setRole]=React.useState("");
  
  console.log(isAuthenticated);

  React.useEffect(() => {    
    onLoad();
  }, []);
  
  async function onLoad() {
    
    try {      
      const data=await Auth.currentAuthenticatedUser();
      //console.log(data);
      setAttributes(data.attributes);
      setRole(data.attributes["custom:rol"]);
      //console.log(data.attributes);
      //const result=parseJwt(data.signInUserSession.idToken.jwtToken);            
      let session=localStorage.getItem("token");
      if(session)
      {
        userHasAuthenticated(true);
      }
      
    }
    catch(e) {
      if (e !== 'No current user') {
        //alert(e);
        console.log(e);
        desconectar();
      }
    }
  
    //setIsAuthenticating(false);
  }


  const desconectar=()=>{
    
    Auth.signOut().then(data=>{
      userHasAuthenticated(false);
      localStorage.clear();
      props.history.push("/"); 
    }).catch(error=>{

    });
    
  }; 

  return (

        <body>

            
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="/" className="item" ><img src={logoAlmadentro}/></Navbar.Brand>
                {!isAuthenticated  && role!="admin" &&
                <div style={{width:"100%"}}>
                    <div style={{float:"right", textAlign:"right"}}>
                        <span style={{display:"block",fontFamily: 'libre-baskerville-italic'}}>¿Dudas? Llámanos al <a href="tel:+34644095959" style={{textDecoration:"none"}}>+34644095959</a><img src={iconoTelefono} style={{height:"1.2rem",marginLeft:"0.3rem"}}/></span>
                        <span style={{display:"block",fontSize:"0.9rem",margin:0}} >o escríbenos a <a href="mailto:hola@almadentro.com">hola@almadentro.com</a></span>
                        <span style={{display:"block",fontSize:"0.9rem",margin:0}} >Lunes a viernes, de 9:00 a 18:00h</span>
                    </div>
                </div>
                }
                
                
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                {isAuthenticated  && role!="admin" &&
                <>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="justify-content-center" style={{ width: "100%"}} >
                            <LinkContainer to={"tualmadentro"}>
                                <Nav.Link  className={activa=="tualmadentro" ? "item activo": "item"}>Tu Almadentro</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={"configuracion"}>
                                <Nav.Link className={activa=="configuracion" ? "item activo": "item"}>Configuración</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={"facturacionpagos"}>
                                <Nav.Link className={activa=="facturacionpagos" ? "item activo": "item"}>Facturación y pagos</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Brand  className="item" style={{width:300,fontSize:12,display:"flex",alignItems:"center",justifyContent:"flex-end"}}><FontAwesomeIcon style={{fontSize:29,paddingRight:10}} icon="user-circle" /><span style={{paddingRight:10}}>{attributes.name+" "+attributes.family_name}</span><span><a href="#" className="link-desconectar" onClick={desconectar}>Desconectar</a></span></Navbar.Brand>
                </>
                }

                {isAuthenticated  && role==="admin" &&
                <>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="justify-content-center" style={{ width: "100%" }} >
                            <LinkContainer to={"/admin/dashboard"}>
                                <Nav.Link  className={activa=="/admin/dashboard" ? "item activo": "item"}>Dashboard</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={"/admin/clientes"}>
                                <Nav.Link className={activa=="/admin/clientes" ? "item activo": "item"}>Clientes</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={"/admin/fechas"}>
                                <Nav.Link className={activa=="/admin/fechas" ? "item activo": "item"}>Fechas</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Brand  className="item" style={{width:300,fontSize:12,display:"flex",alignItems:"center",justifyContent:"flex-end"}}><FontAwesomeIcon style={{fontSize:29,paddingRight:10}} icon="user-circle" /><span style={{paddingRight:10}}>{attributes.name+" "+attributes.family_name}</span><span><a href="#" onClick={desconectar}>Desconectar</a></span></Navbar.Brand>
                </>
                }
            </Navbar>

            <main role="main" >
                <Routes appProps={{ isAuthenticated, userHasAuthenticated,setActiva,attributes,setAttributes,setRole}}/>
            </main>




            {role !="admin" ?
                <footer style={{alignSelf:"flex-end",fontFamily: "'Rubik', sans-serif"}}>
                    <Container fluid>
                        <Row className="py-4" style={{marginRight:"0 !important", marginLeft:"0 !important",width:"100%"}}>
                            <Col  md={4}  xs={12}>
                                <span className="justify-content-center" style={{color: "#4a4a4a", fontWeight:"500"}}>Almadentro &copy; 2020 Todos los derechos reservados</span>
                            </Col>
                            <Col  md={4} xs={12} style={{display:"flex",justifyContent:"center"}}>
                                <a target="_blank" href="https://www.facebook.com/tualmadentro/"><img src={logoFacebook} style={{height:"45px", marginRight:"1rem"}} /></a>
                                <a target="_blank" href="https://www.youtube.com/channel/UC7bqT5Za3F0yqFSYBAMOGqg"><img src={logoYoutube} style={{height:"45px", marginRight:"1rem"}}  /></a>
                                <a target="_blank" href="https://www.instagram.com/alma_dentro/" ><img src={logoInstagram} style={{height:"45px"}}/></a>
                            </Col>
                            <Col md={2}  xs={12}>
                                <span className="justify-content-center"><a target="_blank" style={{textDecoration:"none", color: "#4a4a4a", fontSize:"1rem"}} href="https://almadentro.com/politica-privacidad/"> Politica de privacidad</a></span>
                            </Col>
                            <Col md={1} xs={12} >
                                <span className="justify-content-center"><a target="_blank" style={{textDecoration:"none", color: "#4a4a4a"}} href="https://almadentro.com/cookies/">Cookies</a></span>
                            </Col>
                            <Col md={1}  xs={12} style={{marginRight:"0"}}>
                                <span className="justify-content-center"><a target="_blank" style={{textDecoration:"none", color: "#4a4a4a"}} href="https://almadentro.com/aviso-legal/">Aviso legal</a></span>
                            </Col>
                        </Row>
                    </Container>
                </footer>
                : null}

        </body>



    
  );
}

export default withRouter(App);


/*const mapStateToProps=state=>({token:state.token,attributes:state.attributes});
const mapDispatchToProps=dispatch=>({
  ingresar(data){
      dispatch({
          type:"ingresar",
          data
      })
  }  
});*/

//export default connect(mapStateToProps,mapDispatchToProps)(withRouter(App));